import * as types from './types'

const downloadRequest = platform => ({
  type: types.DOWNLOAD_REQUESTED,
  payload: {platform: platform}
})

const downloadSucceed = () => ({
  type: types.DOWNLOAD_SUCCEEDED
})

const downloadFailed = msg => ({
  type: types.DOWNLOAD_FAILED,
  payload: {msg: msg}
})

export {downloadFailed, downloadRequest, downloadSucceed}

import React, {useState} from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import Constants from '../../commons/constants/constants'
import TeamTableRow from './TeamTableRow'

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  legendContainer: {
    display: 'inline-block',
    padding: Sizes.largeMargin,
    marginTop: Sizes.defaultMargin,
    marginRight: Sizes.defaultMargin
  },
  paginationContainer: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0px',
    float: 'right'
  },
  paginationSelect: {
    backgroundColor: 'white'
  },
  paginationArrow: {
    color: Colors.primary
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  tCellIcon: {
    width: '35px',
    textAlign: 'center',
    paddingLeft: Sizes.defaultPadding,
    paddingRight: Sizes.defaultPadding
  },
  wrapIcon: {
    verticalAlign: 'text-bottom',
    alignItems: 'center'
  }
})

const TeamTable = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const [pagination, setPagination] = useState(Constants.defaultTeamPagination)
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState('asc')
  let headerClass
  let size = 'small'

  const _onPageChanged = (event, page) => {
    const updatedPagination = {
      ...pagination,
      page: page
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _onRowPerPageChanged = (event) => {
    const updatedPagination = {
      ...pagination,
      size: event.target.value
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)
    const updatedPagination = {
      ...pagination,
      sort: `${property},${newOrder}`
    }
    props.onChangePagination(updatedPagination)
  }
  return (
    <TableContainer>
      <h4 className={headerClass}>{props.title}</h4>
      <Table className={classes.table} size={size}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell
              key="name"
              sortDirection={orderBy === 'name' ? order : false}
              className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={_createSortHandler('name')}
                IconComponent={ArrowDropDownIcon}
                classes={{
                  icon: orderBy === 'name' ? classes.tSortIconActive : classes.tSortIconInactive
                }}>
                {t('team.teamName')}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.length > 0 ? (
            props.rows.map((row) => {
              return (
                <TeamTableRow
                  key={row.teamId}
                  team={row}
                  onEditTeam={props.onEditTeam}
                  onDeleteTeam={props.onDeleteTeam}
                />
              )
            })
          ) : (
            <TableRow></TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow></TableRow>
        </TableFooter>
      </Table>

      <TablePagination
        component="div"
        className={classes.paginationContainer}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage={''}
        labelDisplayedRows={({from, to, count}) => `${from} tot ${to} van ${count} teams`}
        count={props.total}
        rowsPerPage={pagination.size}
        page={pagination.page}
        SelectProps={{
          inputProps: {'aria-label': 'rows per page'}
        }}
        onChangePage={_onPageChanged}
        onChangeRowsPerPage={_onRowPerPageChanged}
        nextIconButtonProps={{color: 'primary'}}
        backIconButtonProps={{color: 'primary'}}
        classes={{
          select: classes.paginationSelect,
          actions: classes.paginationArrow
        }}
      />
    </TableContainer>
  )
}

TeamTable.propTypes = {
  rows: PropTypes.array,
  total: PropTypes.number.isRequired,
  onEditTeam: PropTypes.func.isRequired,
  onDeleteTeam: PropTypes.func.isRequired,
  onChangePagination: PropTypes.func.isRequired
}

export default TeamTable

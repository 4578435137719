import React, {Suspense} from 'react'
import {Redirect, Route} from 'react-router'
import {makeStyles} from '@material-ui/core/styles'
import Header from './Header'
import Footer from './Footer'
import LoadingIndicator from './LoadingIndicator'

const useStyles = makeStyles({
  container: {
    paddingBottom: '72px'
  }
})
const PublicRoute = ({component: Component, ...rest}) => {
  const classes = useStyles()
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<LoadingIndicator open={true} />}>
          <Header isPrivate={false} />
          <Component {...props} />
          <Footer />
        </Suspense>
      )}
    />
  )
}

export default PublicRoute

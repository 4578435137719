import React, {useState} from 'react'
import {Button, IconButton, Paper} from '@material-ui/core'
import {useStaticState, Calendar} from '@material-ui/pickers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useTranslation} from 'react-i18next'
import {endOfMonth, getDay, format, startOfMonth, subDays, subMonths} from 'date-fns'
import {nl} from 'date-fns/locale'
import Colors from '../../commons/constants/colors'

const useStyles = makeStyles({
  btn: {
    marginLeft: '0.5rem'
  },
  btnText: {
    textTransform: 'lowercase',
    color: Colors.textLight
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  coloredDate: {
    color: Colors.primary
  },
  filterContainer: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  rangeSelector: {
    width: '100%'
  },
  root: {
    padding: '1rem',
    position: 'relative',
    color: Colors.text
  },
  select: {
    margin: '0.5rem',
    width: '40%',
    color: Colors.textLight,
    display: 'inline-flex'
  }
})

const ReportDateFilter = (props) => {
  const classes = useStyles({})
  const [startDate, handleStartDateChange] = useState(subDays(new Date(), 60))
  const [endDate, handleEndDateChange] = useState(new Date())
  const [dateRange, setDateRange] = useState('custom')
  const [shown, setShown] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState({
    teamId: 0,
    name: 'Alle Teams'
  })
  // you can past mostly all available props, like minDate, maxDate, autoOk and so on
  const {pickerProps: startPickerProps} = useStaticState({
    value: startDate,
    onChange: handleStartDateChange
  })
  const {pickerProps: endPickerProps} = useStaticState({
    value: endDate,
    onChange: handleEndDateChange
  })
  const {t} = useTranslation()

  const _handleRangeChange = (event, child) => {
    const now = new Date()
    setDateRange(child.props.value)
    switch (child.props.value) {
      case 'today':
        handleStartDateChange(now)
        handleEndDateChange(now)
        break
      case 'yesterday':
        handleStartDateChange(subDays(now, 1))
        handleEndDateChange(now)
        break
      case 'lastWeek':
        handleStartDateChange(subDays(now, getDay(now) + 7 - 1))
        handleEndDateChange(subDays(now, getDay(now)))
        break
      case 'lastMonth':
        handleStartDateChange(startOfMonth(subMonths(now, 1)))
        handleEndDateChange(endOfMonth(subMonths(now, 1)))
        break
      case '7days':
        handleStartDateChange(subDays(now, 7 - 1))
        handleEndDateChange(now)
        break
      default:
        break
    }
  }

  const _onResetClicked = async () => {
    handleEndDateChange(new Date())
    handleStartDateChange(subDays(new Date(), 60))
    setSelectedTeam({teamId: 0, name: 'Alle Teams'})
    props.callback(subDays(new Date(), 60), new Date(), 0)
  }

  return (
    <>
      <div className={classes.filterContainer}>
        <Button
          id="_report_WAR_antesportlet_filter-date-selector"
          classes={{text: classes.btnText}}
          onClick={() => setShown(!shown)}
          endIcon={<ExpandMoreIcon color="primary" />}>
          {format(startDate, 'dd MMM yyyy', {locale: nl}) +
            ' - ' +
            format(endDate, 'dd MMM yyyy', {locale: nl})}
        </Button>
        <Autocomplete
          id="combo-box-demo"
          classes={{root: classes.select}}
          options={props.teamList}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => {
            setSelectedTeam(
              value || {
                teamId: 0,
                name: 'Alle Teams'
              }
            )
          }}
          value={selectedTeam}
          size="small"
          renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={() => props.callback(startDate, endDate, selectedTeam.teamId)}>
          {t('common.search')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.btn}
          onClick={_onResetClicked}>
          {t('common.reset')}
        </Button>
      </div>
      <Collapse in={shown}>
        <Paper style={{overflow: 'hidden'}} className={classes.root}>
          <IconButton
            aria-label="close"
            className={classes.closeBtn}
            onClick={() => setShown(false)}>
            <CloseIcon />
          </IconButton>
          <Grid container spacing={0}>
            <Grid item md={4}>
              <Calendar {...startPickerProps} />
            </Grid>
            <Grid item md={4}>
              <Calendar {...endPickerProps} />
            </Grid>
            <Grid item md={4}>
              <p>{t('common.dateFilter.selectRange')}</p>
              <div>
                <div>
                  <Select
                    value={dateRange}
                    defaultValue=""
                    onChange={_handleRangeChange}
                    className={classes.rangeSelector}
                    variant="outlined">
                    <MenuItem selected value="custom">
                      {t('common.dateFilter.undefined')}
                    </MenuItem>
                    <MenuItem value="today">{t('common.dateFilter.today')}</MenuItem>
                    <MenuItem value="yesterday">{t('common.dateFilter.yesterday')}</MenuItem>
                    <MenuItem value="lastWeek">{t('common.dateFilter.lastWeek')}</MenuItem>
                    <MenuItem value="lastMonth">{t('common.dateFilter.lastMonth')}</MenuItem>
                    <MenuItem value="7days">{t('common.dateFilter.last7Days')}</MenuItem>
                  </Select>
                </div>
              </div>
              <p>{t('common.dateFilter.selectedDate')}</p>
              <p className={classes.coloredDate}>
                {format(startDate, 'dd MMM yyyy', {locale: nl}) +
                  ' - ' +
                  format(endDate, 'dd MMM yyyy', {locale: nl})}
              </p>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </>
  )
}

ReportDateFilter.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  callback: PropTypes.func,
  teamList: PropTypes.array
}

export default ReportDateFilter

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import RestoreIcon from '@material-ui/icons/Restore'
import IconButton from '@material-ui/core/IconButton'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  label: {
    fontWeight: 'bold'
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: Colors.text,
    fontSize: '1rem'
  }
})

const AddressTableRow = props => {
  const classes = useStyles()

  return (
    <TableRow className={classes.row} key={props.address.id}>
      <TableCell>
        <Typography className={classes.wrapIcon}>{props.address.address}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.wrapIcon}>{props.address.postalCode}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.wrapIcon}>{props.address.city}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.wrapIcon}>{props.address.additionalInfo}</Typography>
      </TableCell>
      <TableCell className={classes.actionButton}>
        {!props.address.active && (
          <IconButton onClick={() => props.onRestoreAddress(props.address)}>
            <RestoreIcon />
          </IconButton>
        )}
        {props.address.active && (
          <IconButton onClick={() => props.onEditAddress(props.address.id)}>
            <EditOutlinedIcon />
          </IconButton>
        )}

        <IconButton onClick={() => props.onDeleteAddress(props.address)}>
          <DeleteOutlineIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

AddressTableRow.propTypes = {
  key: PropTypes.number,
  address: PropTypes.object,
  onEditAddress: PropTypes.func.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
  onRestoreAddress: PropTypes.func.isRequired
}

export default AddressTableRow

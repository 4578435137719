import * as types from './types'

const INITIAL_STATE = {
  isLoading: false,
  appointmentList: [],
  msg: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APPOINTMENT_WITH_ALARM_REQUESTED:
      return {...state, isLoading: true}
    case types.APPOINTMENT_WITH_ALARM_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        appointmentList: action.payload.appointments
      }
    case types.APPOINTMENT_WITH_ALARM_FAILED:
      return {...state, isLoading: false}
    case types.CLEAR_APPOINTMENT_WITH_ALARM:
      return {INITIAL_STATE}
    default:
      return state
  }
}

export default reducer

const APPOINTMENT_WITH_ALARM_REQUESTED = 'safe/appointment/appointment_with_alarm_requested'
const APPOINTMENT_WITH_ALARM_SUCCEEDED = 'safe/appointment/appointment_with_alarm_succeeded'
const APPOINTMENT_WITH_ALARM_FAILED = 'safe/appointment/appointment_with_alarm_failed'
const CLEAR_APPOINTMENT_WITH_ALARM = 'safe/appointment/clear_appointment_with_alarm'
export {
  APPOINTMENT_WITH_ALARM_REQUESTED,
  APPOINTMENT_WITH_ALARM_SUCCEEDED,
  APPOINTMENT_WITH_ALARM_FAILED,
  CLEAR_APPOINTMENT_WITH_ALARM
}

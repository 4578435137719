import {all, call, put, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as actions from './actions'
import * as types from './types'
import {JsonResponse, ReduxActionType} from '../../commons/interfaces'
import {AxiosResponse} from 'axios'
import {useDispatch} from 'react-redux'

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

function* requestOrgSettings() {
  console.log('request org settings saga')
  try {
    const result: AxiosResponse = yield call(() => RestRequester.getOrgSettings())
    const jsonResponse: JsonResponse = result.data

    if (jsonResponse.success) {
      console.log('success request org settings', jsonResponse)
      yield put(actions.requestOrgSettingsSuccess(jsonResponse.data))
    } else {
      console.log('faile request org settings', jsonResponse)
      yield put(actions.requestOrgSettingsFailed(jsonResponse.message || ''))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.requestOrgSettingsFailed(error))
  }
}

function* saveOrgNameRequest(action: ReduxActionType) {
  console.log('save org settings saga', action)
  try {
    const result: AxiosResponse = yield call(() => RestRequester.saveOrgName(action.payload))
    const jsonResponse: JsonResponse = result.data
    console.log('save result', jsonResponse)
    if (result.status === 200) {
      if (jsonResponse.success) {
        yield put(actions.saveOrgNameSuccess(jsonResponse.data))
      } else {
        yield put(actions.saveOrgNameFail(jsonResponse.message || ''))
      }
    } else {
      yield put(actions.saveOrgNameFail(result.data || 'Error saving organization name'))
    }
  } catch (error) {
    yield put(actions.saveOrgNameFail(error))
  }
}

function* saveOrgLogoRequest(action: ReduxActionType) {
  const _uploadProgressHandler = (progressEvent: any) => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
    if (totalLength !== null) {
      // yield call(Math.round((progressEvent.loaded * 100) / totalLength))
      action.payload.dispatch(
        actions.updateLogoUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength))
      )
    }
  }

  try {
    const result: AxiosResponse = yield call(() =>
      RestRequester.saveOrgLogo(action.payload.logo, _uploadProgressHandler)
    )
    const jsonResponse: JsonResponse = result.data
    console.log('save result', jsonResponse)
    if (result.status === 200) {
      if (jsonResponse.success) {
        const base64 = yield call(() => toBase64(action.payload.logo))
        yield put(actions.saveOrgLogoSuccess(base64))
      } else {
        yield put(actions.saveOrgLogoFail(jsonResponse.message || ''))
      }
    } else {
      yield put(actions.saveOrgLogoFail(result.data || 'Error saving organization logo'))
    }
  } catch (error) {
    yield put(actions.saveOrgLogoFail(error))
  }
}

function* deleteOrgNameRequest(action: ReduxActionType) {
  console.log('delete org settings saga', action)
  try {
    const result: AxiosResponse = yield call(() => RestRequester.deleteOrgName())
    const jsonResponse: JsonResponse = result.data
    if (result.status === 200) {
      if (jsonResponse.success) {
        yield put(actions.removeOrgNameSuccess())
      } else {
        yield put(actions.removeOrgNameFail())
      }
    } else {
      yield put(actions.removeOrgNameFail())
    }
  } catch (error) {
    yield put(actions.removeOrgNameFail())
  }
}

function* deleteOrgLogoRequest(action: ReduxActionType) {
  try {
    const result: AxiosResponse = yield call(() => RestRequester.deleteOrgLogo())
    const jsonResponse: JsonResponse = result.data
    if (result.status === 200) {
      if (jsonResponse.success) {
        yield put(actions.removeOrgLogoSuccess())
      } else {
        yield put(actions.removeOrgLogoFail())
      }
    } else {
      yield put(actions.removeOrgLogoFail())
    }
  } catch (error) {
    yield put(actions.removeOrgLogoFail())
  }
}

function* updateDisable112Settings(action: ReduxActionType) {
  try {
    const result: AxiosResponse = yield call(() =>
      RestRequester.updateAlarm112Settings(action.payload)
    )
    const jsonResponse: JsonResponse = result.data
    if (result.status === 200) {
      if (jsonResponse.success) {
        yield put(actions.updateDisable112Success(jsonResponse.data))
      } else {
        yield put(actions.updateDisable112Fail(jsonResponse.message || ''))
      }
    } else {
      yield put(actions.updateDisable112Fail(jsonResponse.message || ''))
    }
  } catch (error) {
    yield put(actions.updateDisable112Fail(error))
  }
}

function* saga() {
  yield all([
    takeLatest(types.ORG_NAME_REQUEST, requestOrgSettings),
    takeLatest(types.ORG_NAME_SAVE, saveOrgNameRequest),
    takeLatest(types.ORG_LOGO_SAVE, saveOrgLogoRequest),
    takeLatest(types.ORG_NAME_REMOVE, deleteOrgNameRequest),
    takeLatest(types.ORG_LOGO_REMOVE, deleteOrgLogoRequest),
    takeLatest(types.ORG_ENABLE_112_UPDATE_REQUESTED, updateDisable112Settings)
  ])
}

export default saga

import React, {Suspense} from 'react'
import {Redirect, Route} from 'react-router'
import {makeStyles} from '@material-ui/core/styles'
import Header from './Header'
import Footer from './Footer'
import LoadingIndicator from './LoadingIndicator'
import {useEmployeeAuthorization, useEmployeeAuthenticated} from '../hooks/EmployeeIsAuthorized'

const useStyles = makeStyles({
  container: {
    paddingBottom: '72px'
  }
})

const PrivateRoute = ({component: Component, allowedRoles, ...rest}) => {
  const classes = useStyles()
  const isAuthorized = useEmployeeAuthorization(allowedRoles)
  const isLoggedIn = useEmployeeAuthenticated()

  console.log('isLoggedIn', isLoggedIn)
  console.log('isAuthorized', isAuthorized)
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          isAuthorized ? (
            <Suspense fallback={<LoadingIndicator open={true} />}>
              <div className={classes.container}>
                <Header isPrivate={true} />
                <Component {...props} />
                <Footer />
              </div>
            </Suspense>
          ) : (
            // TODO: redirect to a proper unauthorized page in the future
            <Redirect to="medewerker" />
          )
        ) : (
          <Redirect to="login" />
        )
      }
    />
  )
}

export default PrivateRoute

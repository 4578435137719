import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import logo from '../../assets/images/logo.png'
import Sizes from '../../commons/constants/sizes'
import * as actions from './actions'
import {connect} from 'react-redux'
import LoadingIndicator from '../../components/LoadingIndicator'

const useStyles = makeStyles({
  btn: {
    marginTop: Sizes.largeMargin
  },
  container: {
    textAlign: 'center',
    height: '100%',
    justifyContent: 'center'
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    paddingTop: '64px',
    paddingBottom: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '330px'
  },
  logo: {
    width: '100px',
    margin: '16px'
  }
})

const _onDownloadClicked = (props, device) => {
  props.dispatch(actions.downloadRequest(device))
  // switch (device) {
  //   case 'android':
  //     RestRequester.downloadApp(device).then(response => {
  //       const a = document.createElement('a')
  //       document.body.appendChild(a)
  //       a.style = 'display: none'

  //       const blob = new Blob([response.data], {type: 'application/vnd.android.package-archive'}),
  //         url = window.URL.createObjectURL(blob)
  //       console.log('blob size', blob.size)
  //       a.href = url
  //       a.download = 'safe-app.apk'
  //       a.click()
  //       window.URL.revokeObjectURL(url)
  //     })
  //     break
  //   case 'ios':
  //     window.location.href =
  //       'itms-services://?action=download-manifest&url=https://' +
  //       window.location.host +
  //       '/test/v2/api/download/ios'
  //     break
  //   default:
  //     console.error('device type is not supported')
  //     break
  // }
}

const DownloadScreen = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Typography variant="body1">
          Download hieronder de SAFE app en zorg samen met uw collega's voor uw eigen veiligheid en
          die van uw collega's tijdens het werk.
        </Typography>
        <img alt="SAFE" src={logo} className={classes.logo} />
        <Typography variant="h3">Download</Typography>
        <Typography variant="subtitle1">Kies uw platform</Typography>
        <Button
          href={`https://${window.location.host}/admin/m/android/safe-app.apk`}
          variant="contained"
          color="primary"
          className={classes.btn}
          fullWidth>
          Android
        </Button>
        <Button
          href={`itms-services://?action=download-manifest&url=https://${window.location.host}/admin/m/ios/manifest.plist`}
          variant="contained"
          color="primary"
          className={classes.btn}
          fullWidth>
          iOS
        </Button>
        <LoadingIndicator open={props.downloadReducer.isLoading} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {downloadReducer} = state
  return {downloadReducer}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadScreen)

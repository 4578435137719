import * as types from './types'

const addressFetchRequested = (filter, pagination, includeHidden) => ({
  type: types.ADDRESS_FETCH_REQUESTED,
  payload: {filter, pagination, includeHidden}
})

const addressFetchSucceed = addressList => ({
  type: types.ADDRESS_FETCH_SUCCEEDED,
  payload: {addresses: addressList}
})

const addressFetchFailed = msg => ({
  type: types.ADDRESS_FETCH_FAILED,
  payload: {msg}
})

const addressDetailFetchRequested = id => ({
  type: types.ADDRESS_DETAIL_FETCH_REQUESTED,
  payload: {addressId: id}
})

const addressDetailFetchSucceed = address => ({
  type: types.ADDRESS_DETAIL_FETCH_SUCCEEDED,
  payload: {address: address}
})

const addressDetailFetchFailed = msg => ({
  type: types.ADDRESS_DETAIL_FETCH_FAILED,
  payload: {msg}
})

const clearMsg = () => ({
  type: types.CLEAR_MESSAGE
})

const addressInsertRequested = (address, history) => ({
  type: types.ADDRESS_INSERT_REQUESTED,
  payload: {address: address, history: history}
})

const addressInsertSucceed = history => ({
  type: types.ADDRESS_INSERT_SUCCEEDED,
  payload: {history}
})

const addressInsertFailed = msg => ({
  type: types.ADDRESS_INSERT_FAILED,
  payload: {msg}
})

const addressUpdateRequested = (address, history) => ({
  type: types.ADDRESS_UPDATE_REQUESTED,
  payload: {address: address, history: history}
})

const addressUpdateSucceed = history => ({
  type: types.ADDRESS_UPDATE_SUCCEEDED,
  payload: {history}
})

const addressUpdateFailed = msg => ({
  type: types.ADDRESS_UPDATE_FAILED,
  payload: {msg}
})

const addressDeleteRequested = (address, filter, pagination, includeHidden) => ({
  type: types.ADDRESS_DELETE_REQUESTED,
  payload: {address, filter, pagination}
})

const addressDeleteSucceed = (filter, pagination, includeHidden) => ({
  type: types.ADDRESS_DELETE_SUCCEEDED,
  payload: {filter, pagination, includeHidden}
})

const addressDeleteFailed = msg => ({
  type: types.ADDRESS_DELETE_FAILED,
  payload: {msg}
})

const addressUploadRequested = (file, history, filter, pagination, includeHidden) => ({
  type: types.ADDRESS_UPLOAD_REQUESTED,
  payload: {file: file, history: history, filter, pagination, includeHidden}
})

const addressUploadSucceeded = (msg, history, filter, pagination) => ({
  type: types.ADDRESS_UPLOAD_SUCCEEDED,
  payload: {msg: msg, history: history, filter, pagination}
})

const addressUploadFailed = msg => ({
  type: types.ADDRESS_UPLOAD_FAILED,
  payload: {msg: msg}
})

const addressUploadEmpty = () => ({
  type: types.ADDRESS_UPLOAD_EMPTY
})

const addressClearDetail = () => ({
  type: types.CLEAR_ADDRESS_DETAIL
})

const addressDetailEdit = (address) => ({
  type: types.ADDRESS_DETAIL_EDIT,
  payload: address
})

export {
  addressFetchRequested,
  addressFetchSucceed,
  addressFetchFailed,
  addressInsertRequested,
  addressInsertSucceed,
  addressInsertFailed,
  addressDetailEdit,
  addressDetailFetchRequested,
  addressDetailFetchSucceed,
  addressDetailFetchFailed,
  addressUpdateRequested,
  addressUpdateSucceed,
  addressUpdateFailed,
  addressDeleteRequested,
  addressDeleteSucceed,
  addressDeleteFailed,
  addressUploadRequested,
  addressUploadSucceeded,
  addressUploadFailed,
  addressUploadEmpty,
  addressClearDetail,
  clearMsg
}

const required = (value, i18n) => {
  return value ? undefined : i18n.t('common.validation.required')
}

const phoneValidation = (value, i18n) => {
  if (value) {
    return /(^\+31|^0)([0-9]{9}$)/.test(value) ? undefined : i18n.t('common.validation.phone')
  }
  return i18n.t('common.validation.required')
}

const selected = (value, i18n) => {
  return value > 0 ? undefined : i18n.t('common.validation.required')
}

const emailValidation = (value, i18n) => {
  const emailExp = new RegExp(
    /[a-z0-9!#$%&'*+=/?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
  if (value) {
    return emailExp.test(value) ? undefined : i18n.t('common.validation.email')
  }
  return i18n.t('common.validation.required')
}

const postalValidation = (value, i18n) => {
  const postalPattern = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i

  if (value) {
    return postalPattern.test(value) ? undefined : i18n.t('common.validation.postalNotValid')
  }
  return i18n.t('common.validation.required')
}

export {required, phoneValidation, selected, emailValidation, postalValidation}

import React from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Colors from '../commons/constants/colors'
import Sizes from '../commons/constants/sizes'
import {useMediaQuery} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    color: Colors.text,
    textAlign: 'left',
    padding: '16px 0 0 0'
  },
  copyrightContainer: {
    marginBottom: Sizes.defaultMargin
  },
  darkLink: {
    color: Colors.secondary,
    textDecoration: 'none'
  },
  lightLink: {
    color: Colors.primary,
    textDecoration: 'none'
  },
  footer: {
    backgroundColor: Colors.footerBackground,
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    height: 55,
    [theme.breakpoints.down('sm')]: {
      paddingRight: Sizes.largePadding,
      paddingLeft: Sizes.largePadding,
      height: 71
    }
  }
}))
interface FooterProps {}

const Copyright: React.FC<any> = () => {
  return (
    <>
      &copy; {new Date().getFullYear()} <b>SAFE Apps BV</b>
    </>
  )
}

const LandingPage: React.FC<any> = () => {
  const classes = useStyles()

  return (
    <a
      href="https://www.safe-app.nl"
      id="home-link"
      rel="external"
      className={classes.darkLink}
      target="_blank">
      <b>SAFE app</b> website
    </a>
  )
}

const Feedback: React.FC<any> = () => {
  const classes = useStyles()
  return (
    <a href="mailto:support@safe-app.nl" className={classes.darkLink} target="_blank">
      <span className={classes.lightLink}>Stuur</span> feedback
    </a>
  )
}

const Footer: React.FC<any> = () => {
  const classes = useStyles()
  const queryDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        {/* &copy; {new Date().getFullYear()} <b>SAFE Apps BV</b> |{' '} */}
        {queryDownSm ? (
          <>
            <div className={classes.copyrightContainer}>
              <Copyright />
            </div>
            <LandingPage /> | <Feedback />
          </>
        ) : (
          <>
            <Copyright /> | <LandingPage /> | <Feedback />
          </>
        )}
      </Container>
    </footer>
  )
}

export default Footer

import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text
} from 'recharts'
import PropTypes from 'prop-types'
import Colors from '../../commons/constants/colors'

const XAxisTick = (props) => {
  const {x, y, payload} = props

  return (
    // <g transform={`translate(${x},${y})`}>
    //   <text
    //     x={0}
    //     y={0}
    //     dy={16}
    //     textAnchor="end"
    //     fill="#666"
    //     transform="rotate(-90)"
    //   >
    //     {payload.value}
    //   </text>

    // </g>
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        style={{fontSize: 12, color: Colors.text}}
        width={100}
        textAnchor="end"
        verticalAnchor="middle"
        angle={-90}>
        {payload.value}
      </Text>
    </g>
  )
}

const YAxisTick = (props) => {
  const {x, y, payload} = props

  return (
    // <g transform={`translate(${x},${y})`}>
    //   <text
    //     x={0}
    //     y={0}
    //     dy={16}
    //     textAnchor="end"
    //     fill="#666"
    //     transform="rotate(-90)"
    //   >
    //     {payload.value}
    //   </text>

    // </g>
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        style={{fontSize: 12, color: Colors.text}}
        width={100}
        textAnchor="end"
        verticalAnchor="middle">
        {payload.value}
      </Text>
    </g>
  )
}

const TeamSummaryChart = (props) => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={props.data}>
          <XAxis dataKey="teamName" tick={<XAxisTick />} height={100} interval={0} />
          <YAxis minTickGap={1} tick={<YAxisTick />} />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend verticalAlign="top" />
          <Bar name="Aantal alarmen" dataKey="numberAppointmentsWithAlarms" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

TeamSummaryChart.propTypes = {
  data: PropTypes.object
}

export default TeamSummaryChart

const Colors = {
  primary: '#02C9B5',
  primaryDark: '#3a409c',
  primaryDarkest: '#1f278f',
  primaryLight: '#797db5',
  secondary: '#02176F',
  textLight: '#C7C7C7',
  text: '#848484',
  lightGray: '#F5F6FA',
  gray: '#dee2e6',
  background: '#F0F4F7',
  online: '#109A3C',
  byClient: '#549bb1',
  alert: 'red',
  footerBackground: '#E5E3DC'
}

export default Colors

import {
  TEAM_REQUESTED,
  TEAM_SUCCEEDED,
  TEAM_FAILED,
  TEAM_PAGED_REQUESTED,
  TEAM_PAGED_SUCCEEDED,
  TEAM_PAGED_FAILED,
  TEAM_DETAIL_REQUESTED,
  TEAM_DETAIL_SUCCEEDED,
  TEAM_DETAIL_FAILED,
  TEAM_DETAIL_CLEARED,
  TEAM_DETAIL_SAVE_REQUESTED,
  TEAM_DETAIL_SAVE_SUCCEEDED,
  TEAM_DETAIL_SAVE_FAILED,
  TEAM_DETAIL_DELETE_SUCCEEDED,
  TEAM_DETAIL_DELETE_FAILED,
  TEAM_DETAIL_DELETE_REQUESTED,
  ERROR_MSG_CLEAR
} from './types'

const fetchTeamsRequested = () => ({
  type: TEAM_REQUESTED
})
const fetchTeamsSuccess = (teams) => ({
  type: TEAM_SUCCEEDED,
  payload: teams
})

const fetchTeamsFailed = () => ({
  type: TEAM_FAILED
})

const fetchTeamsPagedRequested = (pageable, filter) => ({
  type: TEAM_PAGED_REQUESTED,
  payload: {pageable: pageable, filter: filter}
})
const fetchTeamsPagedSuccess = (teams) => ({
  type: TEAM_PAGED_SUCCEEDED,
  payload: teams
})

const fetchTeamsPagedFailed = () => ({
  type: TEAM_PAGED_FAILED
})

const fetchTeamsDetailRequested = (employeeId) => ({
  type: TEAM_DETAIL_REQUESTED,
  payload: {employeeId: employeeId}
})

const fetchTeamsDetailSuccess = (employeeDetail) => ({
  type: TEAM_DETAIL_SUCCEEDED,
  payload: {employeeDetail}
})

const fetchTeamsDetailFailed = () => ({
  type: TEAM_DETAIL_FAILED
})

const saveTeamsDetailRequested = (team, pagination, filter, form) => ({
  type: TEAM_DETAIL_SAVE_REQUESTED,
  payload: {team: team, pagination: pagination, filter: filter, form: form}
})

const saveTeamsDetailSuccess = (pagination, filter, form) => ({
  type: TEAM_DETAIL_SAVE_SUCCEEDED,
  payload: {pagination, filter, form}
})

const saveTeamsDetailFailed = () => ({
  type: TEAM_DETAIL_SAVE_FAILED
})

const deleteTeamsDetailRequested = (teamId, pagination, filter) => ({
  type: TEAM_DETAIL_DELETE_REQUESTED,
  payload: {teamId: teamId, pagination: pagination, filter: filter}
})

const deleteTeamsDetailSuccess = (pagination, filter) => ({
  type: TEAM_DETAIL_DELETE_SUCCEEDED,
  payload: {pagination, filter}
})

const deleteTeamsDetailFailed = (msg) => ({
  type: TEAM_DETAIL_DELETE_FAILED,
  payload: {msg: msg}
})

const clearErrorMsg = () => ({
  type: ERROR_MSG_CLEAR
})

const clearTeamDetail = () => ({type: TEAM_DETAIL_CLEARED})
export {
  fetchTeamsRequested,
  fetchTeamsSuccess,
  fetchTeamsFailed,
  fetchTeamsPagedRequested,
  fetchTeamsPagedSuccess,
  fetchTeamsPagedFailed,
  fetchTeamsDetailRequested,
  fetchTeamsDetailSuccess,
  fetchTeamsDetailFailed,
  clearTeamDetail,
  saveTeamsDetailRequested,
  saveTeamsDetailSuccess,
  saveTeamsDetailFailed,
  deleteTeamsDetailRequested,
  deleteTeamsDetailSuccess,
  deleteTeamsDetailFailed,
  clearErrorMsg
}

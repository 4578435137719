import React from 'react'
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'

import Colors from '../../commons/constants/colors'

const useStyles = makeStyles({
  title: {
    color: Colors.secondary
  }
})

const AlarmTrendChart = (props) => {
  const classes = useStyles()
  return (
    <div>
      <h3 className={classes.title}>Trend overzicht van alarm meldingen</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={props.data}>
          <XAxis dataKey="key" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

AlarmTrendChart.propTypes = {
  data: PropTypes.object
}

export default AlarmTrendChart

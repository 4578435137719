import * as types from './types'
import {ReduxActionType} from '../../commons/interfaces'

export const saveOrgName = (orgName: string): ReduxActionType => ({
  type: types.ORG_NAME_SAVE,
  payload: orgName
})

export const saveOrgNameSuccess = (orgName: string): ReduxActionType => ({
  type: types.ORG_NAME_SAVE_SUCCESS,
  payload: orgName
})

export const saveOrgNameFail = (errorMsg: string): ReduxActionType => ({
  type: types.ORG_NAME_SAVE_FAIL,
  msg: errorMsg
})

export const editOrgName = (isEditing: boolean): ReduxActionType => ({
  type: types.ORG_NAME_EDIT,
  payload: isEditing
})

export const removeOrgName = (): ReduxActionType => ({
  type: types.ORG_NAME_REMOVE
})

export const removeOrgNameSuccess = (): ReduxActionType => ({
  type: types.ORG_NAME_REMOVE_SUCCESS
})

export const removeOrgNameFail = (): ReduxActionType => ({
  type: types.ORG_NAME_REMOVE_FAIL
})

export const requestOrgSettings = (): ReduxActionType => ({
  type: types.ORG_NAME_REQUEST
})

export const requestOrgSettingsSuccess = (orgName: string): ReduxActionType => ({
  type: types.ORG_NAME_REQUEST_SUCCESS,
  payload: orgName
})

export const requestOrgSettingsFailed = (errorMsg: string): ReduxActionType => ({
  type: types.ORG_NAME_REQUEST_FAIL,
  msg: errorMsg
})

export const saveOrgLogo = (
  logo: File,
  dispatch: (action: ReduxActionType) => void
): ReduxActionType => ({
  type: types.ORG_LOGO_SAVE,
  payload: {logo, dispatch}
})

export const saveOrgLogoSuccess = (base64: any): ReduxActionType => ({
  type: types.ORG_LOGO_SAVE_SUCCESS,
  payload: base64
})

export const saveOrgLogoFail = (errorMsg: string): ReduxActionType => ({
  type: types.ORG_LOGO_SAVE_FAIL,
  msg: errorMsg
})

export const editOrgLogo = (isEditable: boolean): ReduxActionType => ({
  type: types.ORG_LOGO_EDIT,
  payload: isEditable
})

export const removeOrgLogo = (): ReduxActionType => ({
  type: types.ORG_LOGO_REMOVE
})

export const removeOrgLogoSuccess = (): ReduxActionType => ({
  type: types.ORG_LOGO_REMOVE_SUCCESS
})

export const removeOrgLogoFail = (): ReduxActionType => ({
  type: types.ORG_LOGO_REMOVE_FAIL
})

export const updateLogoUploadProgress = (progress: number): ReduxActionType => ({
  type: types.ORG_LOGO_UPLOADING,
  payload: progress
})

export const showNameError = (msg: string): ReduxActionType => ({
  type: types.ORG_NAME_SHOW_ERROR,
  msg: msg
})

export const showLogoError = (msg: string): ReduxActionType => ({
  type: types.ORG_LOGO_SHOW_ERROR,
  msg: msg
})

export const updateDisable112 = (isDisabled: boolean): ReduxActionType => ({
  type: types.ORG_ENABLE_112_UPDATE_REQUESTED,
  payload: isDisabled
})

export const updateDisable112Success = (isDisabled: boolean): ReduxActionType => ({
  type: types.ORG_ENABLE_112_UPDATE_SUCCESS,
  payload: isDisabled
})

export const updateDisable112Fail = (msg: string): ReduxActionType => ({
  type: types.ORG_ENABLE_112_UPDATE_FAIL,
  msg: msg
})

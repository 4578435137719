const urls = {
  employeePage: '/medewerker',
  teamPage: '/team',
  reportPage: '/rapport',
  alarmReceiverPage: '/opvolger',
  releasePage: '/release',
  dashboardPage: '/dashboard',
  heatmapPage: '/heatmap',
  locationPage: '/locatie',
  orgSettingsPage: '/org-settings'
}

export default urls

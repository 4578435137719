import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import LoginScreen from './features/auth/LoginScreen'
import ReportScreen from './features/report/ReportScreen'
import EmployeeScreen from './features/employee/EmployeeScreen'
import TeamScreen from './features/team/TeamScreen'
import DownloadScreen from './features/download/DownloadScreen'
import EmployeeDetailScreen from './features/employee/EmployeeDetailScreen'
import AppointmentScreen from './features/appointment/AppointmentScreen'
import AddressScreen from './features/address/AddressScreen'
import AddressDetailScreen from './features/address/AddressDetailScreen'
import ReleaseScreen from './features/release/ReleaseScreen'
import ReleaseDetailScreen from './features/release/ReleaseDetailScreen'
import {BrowserRouter, Switch} from 'react-router-dom'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {nl} from 'date-fns/locale'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import {createTheme, ThemeProvider} from '@material-ui/core/styles'

import './i18n'
import Colors from './commons/constants/colors'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import reducer from './redux/reducers'
import rootSaga from './sagas'
import ChangePasswordScreen from './features/auth/ChangePasswordScreen'
import ResetPasswordScreen from './features/auth/ResetPasswordScreen'
import urls from './commons/constants/urls'
import OrganizationalSettingsScreen from './features/settings/OrganizationalSettingsScreen'
import {hasRole} from './commons/utils'
import {UserRole} from './commons/enums'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

let middleware = [sagaMiddleware]
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger]
}
// mount it on the Store
const store = createStore(reducer, applyMiddleware(...middleware))

// then run the saga
sagaMiddleware.run(rootSaga)

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.secondary
    },
    secondary: {
      main: Colors.primary
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    }
  }
})
const user = JSON.parse(sessionStorage.getItem('loggedInUser'))
const routing = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
        <BrowserRouter>
          <Switch>
            <PublicRoute exact path="/" component={App} />
            <PublicRoute path="/login" component={LoginScreen} />
            <PrivateRoute path="/rapport" component={ReportScreen} />
            <PrivateRoute path="/medewerker/detail/:employeeId?" component={EmployeeDetailScreen} />
            <PrivateRoute path="/medewerker" component={EmployeeScreen} />
            <PrivateRoute
              path={`${urls.alarmReceiverPage}/detail/:employeeId?`}
              component={EmployeeDetailScreen}
              allowedRoles={[UserRole.ADMIN]}
            />
            <PrivateRoute
              path={urls.alarmReceiverPage}
              component={EmployeeScreen}
              allowedRoles={[UserRole.ADMIN]}
            />
            <PrivateRoute path="/team" component={TeamScreen} />
            <PrivateRoute path="/change-password" component={ChangePasswordScreen} />
            <PrivateRoute path="/heatmap" component={AppointmentScreen} />
            <PrivateRoute
              exact
              path="/locatie"
              allowedRoles={[UserRole.ADMIN, UserRole.CLIENT_ADMIN]}
              component={AddressScreen}
            />
            <PrivateRoute
              path="/locatie/detail/:addressId?"
              allowedRoles={[UserRole.ADMIN, UserRole.CLIENT_ADMIN]}
              component={AddressDetailScreen}
            />
            <PrivateRoute exact path="/release" component={ReleaseScreen} />
            <PrivateRoute path="/release/detail/:releaseId?" component={ReleaseDetailScreen} />
            <PrivateRoute
              path={urls.orgSettingsPage}
              allowedRoles={[UserRole.ADMIN, UserRole.CLIENT_ADMIN]}
              component={OrganizationalSettingsScreen}
            />
            <PublicRoute path="/download" component={DownloadScreen} />
            <PublicRoute path="/resetPassword" component={ResetPasswordScreen} />
            {/* Catch all route */}
            {/* <PublicRoute path="*" status={404} /> */}
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogActions from '@material-ui/core/DialogActions'
import MUIDialogContent from '@material-ui/core/DialogContent'
import MUIDialogContentText from '@material-ui/core/DialogContentText'
import MUIDialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import {useTranslation} from 'react-i18next'

import EmployeeTable from './EmployeeTable'
import * as actions from './actions'
import {Dialog, DialogProps} from '../../components/Dialog'
import EmployeeFilter from './EmployeeFilter'
import LoadingIndicator from '../../components/LoadingIndicator'
import Constants from '../../commons/constants/constants'
import Colors from '../../commons/constants/colors'
import urls from '../../commons/constants/urls'
import {EmployeeFilterField} from '../../commons/enums'
import {EmployeeFilterType, ReduxActionType, TablePaginationType} from '../../commons/interfaces'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F4F7'
  },
  btn: {
    marginLeft: '0.5rem'
  },
  btnContainer: {
    alignSelf: 'center'
  },
  btnText: {
    textTransform: 'lowercase',
    color: '#C7C7C7'
  },
  filterContainer: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  pageTitle: {
    display: 'flex'
  },
  select: {
    margin: '0.5rem',
    minWidth: '300px',
    color: '#C7C7C7'
  },
  tableContainer: {
    padding: '1rem'
  },
  uploadContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    height: 'calc(2.5rem + 2px)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.text
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.6rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: Colors.text,
    padding: '.6rem .5rem'
  }
})

export interface PasswordDialogProps {
  isDialogShown: boolean
  onClose: () => void
  onChangePassword: (adminPassword: string) => void
}
const ChangePasswordDialog: React.FC<PasswordDialogProps> = (props) => {
  const [password, setPassword] = useState('')
  const {t} = useTranslation()
  return (
    <MUIDialog open={props.isDialogShown} onClose={props.onClose} fullWidth>
      <MUIDialogTitle id="delete-dialog">{'SAFE'}</MUIDialogTitle>
      <MUIDialogContent>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          id="name"
          label={t('employee.confirmPassword')}
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
        />
      </MUIDialogContent>
      <MUIDialogActions>
        <Button color="primary" autoFocus onClick={() => props.onChangePassword(password)}>
          {t('employee.resetPassword')}
        </Button>
      </MUIDialogActions>
    </MUIDialog>
  )
}

export interface ImportDialogProps {
  isDialogShown: boolean
  onClose: () => void
  onImportClicked: (file: any) => void
}

const ImportEmployeeDialog: React.FC<ImportDialogProps> = (props) => {
  const {t} = useTranslation()
  const defaultFile = {name: t('common.chooseFile'), size: 0}
  const [file, setFile] = useState(defaultFile)
  const classes = useStyles()
  const _onUploadChange = (event: any) => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      setFile(uploadedFile)
    }
  }

  return (
    <MUIDialog
      open={props.isDialogShown}
      onClose={() => {
        setFile(defaultFile)
        props.onClose()
      }}>
      <MUIDialogTitle id="delete-dialog">{'SAFE'}</MUIDialogTitle>
      <MUIDialogContent>
        <MUIDialogContentText>{t('employee.upload')}</MUIDialogContentText>
        <div className={classes.uploadContainer}>
          <input
            accept=".xlsx,.xls"
            className={classes.uploadInput}
            id="contained-button-file"
            type="file"
            onChange={_onUploadChange}
          />
          <label htmlFor="contained-button-file" className={classes.uploadLbl}>
            {file.name}
          </label>
          <AddCircleIcon className={classes.uploadIcon} />
        </div>
      </MUIDialogContent>
      <MUIDialogActions>
        <Button color="primary" autoFocus onClick={() => props.onImportClicked(file)}>
          {t('employee.upload')}
        </Button>
      </MUIDialogActions>
    </MUIDialog>
  )
}

const emptyDialog: DialogProps = {isShown: false, msg: '', onConfirm: () => {}, onCancel: () => {}}

export interface Props {
  employeeReducer: any
  dispatch: (action: ReduxActionType) => void
  history: any
}

const EmployeeScreen: React.FC<Props> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {t} = useTranslation()

  const [dialogProps, setDialogProps] = useState<DialogProps>(emptyDialog)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0)
  const [isPasswordDialogShown, showPasswordDialog] = useState<boolean>(false)
  const [isImportDialogShown, showImportDialog] = useState<boolean>(false)
  const [filter, setFilter] = useState<EmployeeFilterType>(Constants.defaultEmployeeFilter)
  const [pagination, setPagination] = useState<TablePaginationType>(
    Constants.defaultEmployeePagination
  )

  const {pathname} = props.history.location
  const topPath = `/${pathname.split('/')[1]}`
  const isDAR = topPath === urls.alarmReceiverPage

  const _onEditEmployee = (employeeId: number) =>
    history.push(`${topPath}/detail/${employeeId}`, {isDAR: isDAR})
  const _onDeletePressed = (employeeId: number) => {
    console.log('deleting', employeeId)
    setSelectedEmployeeId(employeeId)
    setDialogProps({
      isShown: true,
      msg: isDAR ? t('alarmReceiver.deleteConfirmation') : t('employee.deleteConfirmation'),
      onConfirm: () => _doDeleteEmployee(employeeId),
      onCancel: () => setDialogProps(emptyDialog)
    })
  }

  const _onChangePasswordPressed = (employeeId: number) => {
    setSelectedEmployeeId(employeeId)
    showPasswordDialog(true)
  }

  const _doDeleteEmployee = (employeeId: number) => {
    console.log('doDeleteEmployee', selectedEmployeeId)
    props.dispatch(actions.deleteEmployeeRequested(employeeId, props.history))
    setSelectedEmployeeId(0)
    setDialogProps(emptyDialog)
  }
  const _doChangePassword = (adminPassword: string) => {
    showPasswordDialog(false)
    props.dispatch(actions.resetPasswordEmployeeByAdminRequested(selectedEmployeeId, adminPassword))
  }

  const _onSearch = (updatedFilter: EmployeeFilterType) => {
    setFilter(updatedFilter)
    props.dispatch(actions.fetchEmployeeRequested(updatedFilter, pagination, isDAR))
  }

  const _onChangePagination = (changedPagination: TablePaginationType) => {
    setPagination(changedPagination)
    props.dispatch(actions.fetchEmployeeRequested(filter, changedPagination, isDAR))
  }

  const _onImportClicked = () => {
    showImportDialog(true)
  }

  useEffect(() => {
    document.title = `${t('common.appName')} | ${
      isDAR ? t('alarmReceiver.pageTitle') : t('employee.title')
    }`

    setDialogProps(emptyDialog)
    setSelectedEmployeeId(0)
    showPasswordDialog(false)
    showImportDialog(false)
    setFilter(Constants.defaultEmployeeFilter)
    setPagination(Constants.defaultEmployeePagination)

    props.dispatch(
      actions.fetchEmployeeRequested(
        Constants.defaultEmployeeFilter,
        Constants.defaultEmployeePagination,
        isDAR
      )
    )
    props.dispatch(actions.fetchTeamsRequested())

    return function cleanup() {
      props.dispatch(actions.clearErrorMsg())
      props.dispatch(actions.clearSuccessMsg())
    }
  }, [topPath])

  return (
    <>
      <Container>
        {props.employeeReducer.detailSuccessMsg && (
          <Alert severity="success" onClose={() => props.dispatch(actions.clearSuccessMsg())}>
            {props.employeeReducer.detailSuccessMsg}
          </Alert>
        )}
        {props.employeeReducer.detailErrorMsg && (
          <Alert
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => props.dispatch(actions.clearErrorMsg())}>
                <CloseIcon />
              </Button>
            }>
            {t(props.employeeReducer.detailErrorMsg)}
          </Alert>
        )}
        <div className={classes.pageTitle}>
          <h3>{isDAR ? t('alarmReceiver.pageTitle') : t('employee.title')}</h3>
          <div className={classes.btnContainer}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.btn}
              onClick={() => history.push(`${topPath}/detail`, {isDAR: isDAR})}>
              {t('common.add')}
            </Button>
            {!isDAR && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.btn}
                  onClick={_onImportClicked}>
                  {t('common.import')}
                </Button>
                <Button
                  href={process.env.PUBLIC_URL + '/templates/Medewerker.xlsx'}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.btn}>
                  {t('employee.downloadTemplate')}
                </Button>
              </>
            )}
          </div>
        </div>
        <Grid container spacing={0}>
          <Grid item md={9}>
            <EmployeeFilter
              teams={props.employeeReducer.listTeam}
              onSearch={_onSearch}
              excludedFields={isDAR ? [EmployeeFilterField.BATTERY] : []}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item md={12}>
            <EmployeeTable
              rows={props.employeeReducer.listEmployee}
              total={props.employeeReducer.totalEmployee}
              onChangePassword={_onChangePasswordPressed}
              onDeleteEmployee={_onDeletePressed}
              onEditEmployee={_onEditEmployee}
              onChangePagination={_onChangePagination}
              isDar={isDAR}
              isBatteryLoading={props.employeeReducer.isBatteryLoading}
              batteryLevel={props.employeeReducer.batteryLevel}
              onRowExpanded={(employeeId) =>
                props.dispatch(actions.fetchEmployeeButtonRequested(employeeId))
              }
            />
          </Grid>
        </Grid>
        <Dialog
          isShown={dialogProps.isShown}
          msg={dialogProps.msg}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
        />

        <ChangePasswordDialog
          isDialogShown={isPasswordDialogShown}
          onChangePassword={_doChangePassword}
          onClose={() => showPasswordDialog(false)}
        />

        <ImportEmployeeDialog
          isDialogShown={isImportDialogShown}
          onImportClicked={(file) => {
            showImportDialog(false)
            if (file.size > 0) {
              props.dispatch(actions.uploadEmployeeRequested(file))
            } else {
              props.dispatch(actions.errorFileEmpty())
            }
          }}
          onClose={() => showImportDialog(false)}
        />
        <LoadingIndicator open={props.employeeReducer.isLoading} />
      </Container>
    </>
  )
}

EmployeeScreen.propTypes = {
  employeeReducer: PropTypes.object
}

const mapStateToProps = (state: any) => {
  const {employeeReducer} = state
  return {employeeReducer}
}

const mapDispatchToProps = (dispatch: (action: ReduxActionType) => void) => {
  return {
    dispatch: (action: ReduxActionType) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeScreen)

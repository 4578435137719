import * as types from './types'

const INITIAL_STATE = {
  detailTeam: {teamId: '', name: '', testCase: false},
  listTeam: [],
  pagedTeams: [],
  isLoading: false,
  totalTeam: 0,
  errorMsg: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TEAM_REQUESTED:
      return {...state, isLoading: true}
    case types.TEAM_SUCCEEDED:
      return {...state, isLoading: false, listTeam: action.payload.data}
    case types.TEAM_PAGED_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        pagedTeams: action.payload.data.data,
        totalTeam: action.payload.data.recordsFiltered
      }
    case types.TEAM_DETAIL_REQUESTED:
      return {...state, isLoading: true}
    case types.TEAM_DETAIL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        detailTeam: action.payload.employeeDetail.data
      }
    case types.TEAM_DETAIL_CLEARED:
      return {...state, detailTeam: {teamId: '', name: ''}}
    case types.TEAM_DETAIL_SAVE_REQUESTED:
      return {...state, isLoading: true}
    case types.TEAM_DETAIL_SAVE_FAILED:
      return {...state, isLoading: false}
    case types.TEAM_DETAIL_SAVE_SUCCEEDED:
      return {...state, isLoading: false}
    case types.TEAM_DETAIL_DELETE_REQUESTED:
      return {...state, isLoading: true}
    case types.TEAM_DETAIL_DELETE_SUCCEEDED:
      return {...state, isLoading: false}
    case types.TEAM_DETAIL_DELETE_FAILED:
      return {...state, isLoading: false, errorMsg: `server_response:${action.payload.msg}`}
    case types.ERROR_MSG_CLEAR:
      return {...state, errorMsg: ''}
    default:
      return state
  }
}

export default reducer

export interface SettingsState {
  orgName?: string | null
  orgLogo?: any
  isNameLoading: boolean
  isNameEditable: boolean
  isLogoLoading: boolean
  isLogoEditable: boolean
  nameErrorKey?: string | null
  logoErrorKey?: string | null
  uploadProgress: number | null
  isAlarm112Disabled: false
  isAlarm112Loading: false
}

export const ORG_NAME_SAVE = 'safe/orgsettings/default_name/save'
export const ORG_NAME_SAVE_SUCCESS = 'safe/orgsettings/default_name/save_success'
export const ORG_NAME_SAVE_FAIL = 'safe/orgsettings/default_name/save_fail'
export const ORG_NAME_REMOVE = 'safe/orgsettings/name/remove'
export const ORG_NAME_REMOVE_SUCCESS = 'safe/orgsettings/name/remove_success'
export const ORG_NAME_REMOVE_FAIL = 'safe/orgsettings/name/remove_fail'
export const ORG_NAME_EDIT = 'safe/orgsettings/name_edit'
export const ORG_NAME_REQUEST = 'safe/orgsettings/name/request'
export const ORG_NAME_REQUEST_SUCCESS = 'safe/orgsettings/name/request_success'
export const ORG_NAME_REQUEST_FAIL = 'safe/orgsettings/name/request_fail'
export const ORG_NAME_SHOW_ERROR = 'safe/orgsettings/name/show_error'

export const ORG_LOGO_SAVE = 'safe/orgsettings/default_logo/save'
export const ORG_LOGO_SAVE_SUCCESS = 'safe/orgsettings/default_logo/save_success'
export const ORG_LOGO_SAVE_FAIL = 'safe/orgsettings/default_logo/save_fail'
export const ORG_LOGO_EDIT = 'safe/orgsettings/logo_edit'
export const ORG_LOGO_REMOVE = 'safe/orgsettings/logo/remove'
export const ORG_LOGO_REMOVE_SUCCESS = 'safe/orgsettings/logo/remove_success'
export const ORG_LOGO_REMOVE_FAIL = 'safe/orgsettings/logo/remove_fail'
export const ORG_LOGO_UPLOADING = 'safe/orgsettings/logo/uploading'
export const ORG_LOGO_SHOW_ERROR = 'safe/orgsettings/logo/show_error'

export const ORG_ENABLE_112_UPDATE_REQUESTED = 'safe/orgsettings/112/update'
export const ORG_ENABLE_112_UPDATE_SUCCESS = 'safe/orgsettings/112/update_success'
export const ORG_ENABLE_112_UPDATE_FAIL = 'safe/orgsettings/112/update_fail'

import React from 'react'
import {KeyboardDatePicker} from '@material-ui/pickers'

const DatePickerAdapter = ({
  input: {name, onChange, value, ...restInput},
  meta,
  inputFormat,
  ...rest
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  return (
    <KeyboardDatePicker
      name={name}
      disableToolbar
      error={showError}
      helperText={showError ? meta.error || meta.submitError : undefined}
      variant="inline"
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      format={inputFormat}
      {...rest}
    />
  )
}

export default DatePickerAdapter

import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'

import LoadingIndicator from '../../components/LoadingIndicator'
import sizes from '../../commons/constants/sizes'

export interface Props {
  onAddNote: () => void,
  isAdmin: boolean,
  isLoading: boolean,
  isDeleteDialogShown: boolean,
  onDeleteConfirm: () => void,
  onDeleteCancel: () => void,
}

const useStyles = makeStyles({
  btn: {
    marginLeft: '0.5rem',
  },
  pageTitle: {
    display: 'inline',
    paddingRight: sizes.defaultPadding
  },
  list: {
    backgroundColor: 'white',
    marginTop: sizes.defaultMargin,
    width: '100%'
  }
})

const ReleaseScreenView: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Container>
        <div>
          <h3 className={classes.pageTitle}>{t('release.title')}</h3>
          { props.isAdmin && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={props.onAddNote}
              size="small">
              {t('release.addNew')}
            </Button>
          )}
        </div>
        <div>
          <List className={classes.list}>
            {props.children}
          </List>
        </div>
      </Container>
      <Dialog open={props.isDeleteDialogShown}>
        <DialogTitle id="delete-dialog">{'SAFE'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('release.deleteWarning')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.onDeleteConfirm}>
            {t('common.yes')}
          </Button>
          <Button color="primary" autoFocus onClick={props.onDeleteCancel}>
          {t('common.no')}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingIndicator open={props.isLoading} />
    </>
  )
}

export default ReleaseScreenView
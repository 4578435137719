import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {makeStyles} from '@material-ui/core/styles'

import Colors from '../commons/constants/colors'

export interface Props {
  accept: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const useStyles = makeStyles({
  uploadContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    height: 'calc(2.5rem + 2px)'
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.25rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: Colors.text,
    padding: '.375rem .75rem'
  }
})

const UploadButton: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const defaultFile = {name: props.label, size: 0}
  const classes = useStyles()
  let inputRef: HTMLInputElement | null
  return (
    <div className={classes.uploadContainer}>
      <input
        id="button-upload"
        accept={props.accept}
        type="file"
        onChange={props.onChange}
        className={classes.uploadInput}
        ref={(input) => (inputRef = input)}
      />
      <label htmlFor="button-upload" className={classes.uploadLbl}>
        {props.label}
      </label>
      <AddCircleIcon className={classes.uploadIcon} onClick={() => inputRef?.click()} />
    </div>
  )
}

export default UploadButton

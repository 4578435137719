import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {Form, FormRenderProps} from 'react-final-form'
import {useTranslation} from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkboxes from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

// import {Autocomplete, Checkboxes, Select, TextField} from 'mui-rff'

import * as actions from './actions'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'

import {hasRole} from '../../commons/utils'
import UploadButton from '../../components/UploadButton'
import {ReduxActionType, Team} from '../../commons/interfaces'
import {UserRole} from '../../commons/enums'
import {MenuItem} from '@material-ui/core'

const useStyles = makeStyles({
  heading: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  btn: {
    marginRight: '0.5rem'
  },
  btnContainer: {
    marginBottom: Sizes.defaultMargin
  },
  chkBox: {
    color: Colors.primary
  },
  inputField: {
    color: Colors.textLight
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.25rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: Colors.text,
    padding: '.375rem .75rem'
  },
  uploadContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    height: 'calc(2.5rem + 2px)'
  },
  root: {
    objectFit: 'contain'
  },
  section: {
    marginBottom: Sizes.largeMargin
  }
})

type TParams = {
  employeeId: string | undefined
}

type TState = {
  isDAR: boolean
}

interface ScreenProps extends RouteComponentProps<TParams, any, TState | any> {
  employeeReducer: any
  dispatch: (action: ReduxActionType) => void
}

interface FormErrors {
  isValid: boolean
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  teamDTOs?: string
  testCase?: string
  systemRoles?: string
}

interface FormData {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  teamDTOs?: any
  testCase?: boolean
  systemRoles?: any
  inAllTeams: boolean
}

interface RoleOptionType {
  label: string
  value: string
}

const EmployeeDetailScreen: React.FC<ScreenProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {t} = useTranslation()

  const {employeeId} = props.match.params
  const {isDAR} = props.location.state
  const isDARUser = Boolean(isDAR)

  const defaultFile = {name: t('common.chooseFile'), size: 0}
  const [file, setFile] = useState(defaultFile)
  const [isAssignedToAllTeams, setAssignedToAllTeams] = useState(false)
  const [error, setError] = useState<FormErrors>()
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    teamDTOs: [],
    testCase: false,
    systemRoles: isDARUser ? 'ROLE_DAR' : 'ROLE_USER',
    inAllTeams: false
  })

  useEffect(() => {
    props.dispatch(actions.clearErrorMsg())
    props.dispatch(actions.clearSuccessMsg())

    if (employeeId) {
      props.dispatch(actions.fetchEmployeeDetailRequested(employeeId))
    }

    props.dispatch(actions.fetchTeamsRequested())

    return function cleanup() {
      props.dispatch(actions.removeEmployeeDetail())
    }
  }, [])

  useEffect(() => {
    const formData = {
      firstName: props.employeeReducer.detailEmployee.firstName ?? '',
      lastName: props.employeeReducer.detailEmployee.lastName ?? '',
      email: props.employeeReducer.detailEmployee.email ?? '',
      phone: props.employeeReducer.detailEmployee.phone ?? '',
      teamDTOs: props.employeeReducer.detailEmployee.teamDTOs || [],
      testCase: props.employeeReducer.detailEmployee.testCase,
      systemRoles:
        (props.employeeReducer.detailEmployee.systemRoles &&
          props.employeeReducer.detailEmployee.systemRoles.length > 0 &&
          props.employeeReducer.detailEmployee.systemRoles[0].name) ||
        (isDARUser ? 'ROLE_DAR' : 'ROLE_USER'),
      inAllTeams: props.employeeReducer.detailEmployee.inAllTeams
    }
    setFormData(formData)
  }, [props.employeeReducer.detailEmployee])

  const _onSaveEmployee = (event) => {
    event.preventDefault()
    const validation = _validateForm(formData)
    if (validation.isValid) {
      const detailEmployee = {
        ...props.employeeReducer.detailEmployee,
        ...formData,
        teamDTOs: isAssignedToAllTeams ? props.employeeReducer.listTeam : formData.teamDTOs,
        systemRoles: [{name: formData.systemRoles}],
        inAllTeams: isAssignedToAllTeams
      }

      if (props.employeeReducer.detailEmployee.employeeId) {
        props.dispatch(actions.updateEmployeeDetailRequested(detailEmployee, history))
      } else {
        props.dispatch(actions.saveEmployeeDetailRequested(detailEmployee, history))
      }
    } else {
      setError(validation)
    }
  }

  const _onBackPressed = () => {
    props.dispatch(actions.removeEmployeeDetail())
    history.goBack()
  }

  const _onUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input: HTMLInputElement = event.target
    const files = input.files

    if (files !== null) {
      const uploadedFile: File = files[0]
      setFile(uploadedFile)
    }
  }

  const _onUploadPressed = () => {
    if (file.size > 0) {
      props.dispatch(actions.uploadEmployeeRequested(file, history))
    } else {
      props.dispatch(actions.errorFileEmpty())
    }
  }

  const _validateForm = (values: any) => {
    const errors: FormErrors = {
      isValid: true
    }
    var emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    if (!values.firstName) {
      errors.isValid = false
      errors.firstName = t('common.validation.required')
    }

    if (!values.lastName) {
      errors.isValid = false
      errors.lastName = t('common.validation.required')
    }

    if (!values.email) {
      errors.isValid = false
      errors.email = t('common.validation.required')
    } else if (!emailRegex.test(values.email)) {
      errors.isValid = false
      errors.email = t('employee.msg.wrongEmail')
    }

    if (!values.phone) {
      errors.isValid = false
      errors.phone = t('common.validation.required')
    }

    if ((!values.teamDTOs || values.teamDTOs.length < 1) && !isAssignedToAllTeams) {
      errors.isValid = false
      errors.teamDTOs = t('common.validation.required')
    }

    return errors
  }

  const _isTeamManager = () => {
    return props.employeeReducer.detailEmployee.systemRoles.find(
      (role: any) => role.name === 'ROLE_TEAMMANAGER'
    )
      ? true
      : false
  }

  const _getRoles = () => {
    const roleAdmin: RoleOptionType = {
      label: t('employee.role.admin'),
      value: UserRole.ADMIN
    }
    const roleTeamManager: RoleOptionType = {
      label: t('employee.role.teamManager'),
      value: UserRole.TEAM_MANAGER
    }
    const roleClientAdmin: RoleOptionType = {
      label: t('employee.role.clientAdmin'),
      value: UserRole.CLIENT_ADMIN
    }
    const roleUser: RoleOptionType = {
      label: t('employee.role.user'),
      value: UserRole.USER
    }
    const roleDar: RoleOptionType = {
      label: t('employee.role.dar'),
      value: UserRole.DAR
    }

    if (isDARUser) {
      return [roleDar]
    } else {
      let roleOptions: Array<RoleOptionType> = []
      if (hasRole(loggedInUser.systemRoles, UserRole.TEAM_MANAGER)) {
        roleOptions = [roleTeamManager, roleUser]
      } else if (hasRole(loggedInUser.systemRoles, UserRole.CLIENT_ADMIN)) {
        roleOptions = [roleClientAdmin, roleTeamManager, roleUser]
      } else {
        roleOptions = [roleAdmin, roleClientAdmin, roleTeamManager, roleUser]
      }
      return roleOptions
    }
  }

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '')

  return (
    <Container className={classes.root}>
      {!isDAR && (
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            className={classes.btn}
            href={process.env.PUBLIC_URL + '/templates/Medewerker.xlsx'}>
            {t('employee.downloadTemplate')}
          </Button>
        </div>
      )}

      {((props.employeeReducer && props.employeeReducer.detailEmployee.teamDTOs) ||
        !props.match.params.employeeId) && (
        <Grid item md={9} className={classes.section}>
          <Typography className={classes.heading}>{t('employee.addEmployee')}</Typography>
          <form onSubmit={_onSaveEmployee}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  name="firstName"
                  id="firstName"
                  label={t('employee.firstName')}
                  className={classes.inputField}
                  inputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                  size="small"
                  error={!!error?.firstName}
                  helperText={error?.firstName || ''}
                  fullWidth
                  value={formData?.firstName || ''}
                  onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  name="lastName"
                  id="lastName"
                  label={t('employee.lastName')}
                  className={classes.inputField}
                  inputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                  size="small"
                  error={!!error?.lastName}
                  helperText={error?.lastName || ''}
                  fullWidth
                  value={formData?.lastName || ''}
                  onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  name="email"
                  id="email"
                  label={t('employee.email')}
                  className={classes.inputField}
                  inputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!!error?.email}
                  helperText={error?.email || ''}
                  value={formData?.email || ''}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  name="phone"
                  id="phone"
                  label={t('employee.phone')}
                  className={classes.inputField}
                  inputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                  size="small"
                  error={!!error?.phone}
                  helperText={error?.phone || ''}
                  value={formData?.phone || ''}
                  onChange={(e) => setFormData({...formData, phone: e.target.value})}
                  fullWidth
                />
              </Grid>
            </Grid>
            {!isAssignedToAllTeams && (
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={props.employeeReducer.listTeam}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => value.teamId === option.teamId}
                    value={formData.teamDTOs ?? []}
                    filterSelectedOptions
                    onChange={(event, value) => setFormData({...formData, teamDTOs: value})}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('employee.selectTeam')}
                        error={!!error?.teamDTOs}
                        helperText={error?.teamDTOs || ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {isDARUser && (
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControlLabel
                    control={
                      <Checkboxes
                        checked={isAssignedToAllTeams}
                        onChange={() => {
                          setAssignedToAllTeams(!isAssignedToAllTeams)
                        }}
                        name="inAllTeams"
                        color="secondary"
                      />
                    }
                    label={t('employee.inAllTeams')}
                  />
                </Grid>
              </Grid>
            )}
            {props.employeeReducer.detailEmployee.employeeId && loggedInUser && !isDAR && (
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Select
                    name="systemRoles"
                    variant="outlined"
                    fullWidth
                    SelectDisplayProps={{style: {backgroundColor: 'white'}}}
                    margin="dense"
                    value={formData.systemRoles}
                    onChange={(e) => setFormData({...formData, systemRoles: e.target.value})}
                    disabled={isDARUser}>
                    {_getRoles().map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            )}
            {loggedInUser && hasRole(loggedInUser.systemRoles, UserRole.ADMIN) && !isDAR && (
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControlLabel
                    control={
                      <Checkboxes
                        checked={formData.testCase || false}
                        onChange={(e) => {
                          setFormData({...formData, testCase: !formData.testCase})
                        }}
                        name="testCase"
                        color="secondary"
                      />
                    }
                    label={t('common.markTestCase')}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Button
                  // disabled={formProps.submitting}
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="submit"
                  className={classes.btn}>
                  {t('common.save')}
                </Button>
                <Button
                  // disabled={formProps.submitting}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className={classes.btn}
                  onClick={_onBackPressed}>
                  {t('common.cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
      <Grid item md={9} className={classes.section}>
        {props.employeeReducer.detailErrorMsg && (
          <Alert severity="error" onClose={() => props.dispatch(actions.clearErrorMsg())}>
            {t(props.employeeReducer.detailErrorMsg)}
          </Alert>
        )}
      </Grid>
      {!isDAR && (
        <Grid item md={9} className={classes.section}>
          <Typography className={classes.heading}>{t('employee.importEmployee')}</Typography>
          <Grid item md={4} className={classes.uploadContainer}>
            <UploadButton accept=".xlsx,.xls" onChange={_onUploadChange} label={file.name} />
          </Grid>
          <Grid item md={12}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.btn}
              onClick={_onUploadPressed}>
              {t('employee.upload')}
            </Button>
          </Grid>
        </Grid>
      )}
      <LoadingIndicator open={props.employeeReducer.isLoading} />
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  const {employeeReducer} = state
  return {employeeReducer}
}

const mapDispatchToProps = (dispatch: (action: ReduxActionType) => void) => {
  return {
    dispatch: (action: ReduxActionType) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetailScreen)

import {all} from 'redux-saga/effects'
import employeeSaga from '../features/employee/sagas'
import authSaga from '../features/auth/sagas'
import teamSaga from '../features/team/sagas'
import downloadSaga from '../features/download/sagas'
import appointmentSaga from '../features/appointment/sagas'
import addressSaga from '../features/address/sagas'
import settingsSaga from '../features/settings/sagas'

export default function* rootSaga() {
  yield all([
    employeeSaga(),
    authSaga(),
    teamSaga(),
    downloadSaga(),
    appointmentSaga(),
    addressSaga(),
    settingsSaga()
  ])
}

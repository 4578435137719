import React, {useState} from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Paper from '@material-ui/core/Paper'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import Table, {Size} from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import Constants from '../../commons/constants/constants'
import EmployeeTableRow from './EmployeeTableRow'
import {Employee, ReduxActionType, TablePaginationType, Team} from '../../commons/interfaces'

export interface Props {
  isDar?: boolean
  rows: Array<Employee>
  title?: string
  total: number
  batteryLevel?: Array<string>
  isBatteryLoading?: Array<boolean>
  onEditEmployee: (employeeId: number) => void
  onDeleteEmployee: (employeeId: number) => void
  onChangePassword: (employeeId: number) => void
  onChangePagination: (pagination: TablePaginationType) => void
  onRowExpanded?: (employeeId: number) => void
}

export interface HeadCell {
  id: string
  disablePadding: boolean
  label: string
  isSort: boolean
}

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  legendContainer: {
    display: 'inline-block',
    padding: Sizes.largeMargin,
    marginTop: Sizes.defaultMargin,
    marginRight: Sizes.defaultMargin
  },
  paginationContainer: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0px',
    float: 'right'
  },
  paginationSelect: {
    backgroundColor: 'white'
  },
  paginationArrow: {
    color: Colors.primary
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  tSortIconActive: {
    opacity: 1,
    color: Colors.primaryDarkest
  },
  tSortIconInactive: {
    opacity: 0.2,
    color: Colors.primaryDarkest
  },
  tCellIcon: {
    width: '35px',
    textAlign: 'center',
    paddingLeft: Sizes.defaultPadding,
    paddingRight: Sizes.defaultPadding
  },
  wrapIcon: {
    verticalAlign: 'text-bottom',
    alignItems: 'center'
  }
})

const EmployeeTable: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const [pagination, setPagination] = useState(Constants.defaultEmployeePagination)
  const [orderBy, setOrderBy] = useState('firstName')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  let headerClass
  let size: Size = 'small'
  const _onPageChanged = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    const updatedPagination = {
      ...pagination,
      page: page
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _onRowPerPageChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedPagination: TablePaginationType = {
      ...pagination,
      size: Number(event?.target.value)
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)
    const updatedPagination = {
      ...pagination,
      sort: `${property},${newOrder}`
    }
    props.onChangePagination(updatedPagination)
  }

  const headCells: Array<HeadCell> = [
    {id: 'firstName', disablePadding: true, label: t('employee.employeeName'), isSort: true},
    {id: 'email', disablePadding: false, label: t('employee.email'), isSort: true},
    {id: 'team.name', disablePadding: false, label: t('employee.teamName'), isSort: false}
  ]

  const _parseHeadCell = ({id, isSort, label}: HeadCell) => {
    return (
      <TableCell
        key={id}
        sortDirection={orderBy === id ? order : false}
        className={classes.tableHeadCell}>
        {isSort ? (
          <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={_createSortHandler(id)}
            IconComponent={ArrowDropDownIcon}
            classes={{
              icon: orderBy === id ? classes.tSortIconActive : classes.tSortIconInactive
            }}>
            {label}
          </TableSortLabel>
        ) : (
          label
        )}
      </TableCell>
    )
  }

  return (
    <TableContainer>
      <h4 className={headerClass}>{props.title}</h4>
      <Table className={classes.table} size={size}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell key="icon" className={classes.tableHeadCell}></TableCell>
            {headCells.map((headCell) => _parseHeadCell(headCell))}
            <TableCell key="actions" className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.length > 0 ? (
            props.rows.map((row) => (
              <EmployeeTableRow
                isDAR={props.isDar}
                key={row.employeeId}
                employee={row}
                batteryLevel={props.batteryLevel ? props.batteryLevel[row.employeeId] : ''}
                onEditEmployee={props.onEditEmployee}
                onChangePassword={props.onChangePassword}
                onDeleteEmployee={props.onDeleteEmployee}
                onRowExpanded={props.onRowExpanded}
                isBatteryLoading={props.isBatteryLoading}
              />
            ))
          ) : (
            <TableRow></TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow></TableRow>
        </TableFooter>
      </Table>
      <Paper className={classes.legendContainer}>
        <Typography className={classes.wrapIcon} style={{color: Colors.online}}>
          <PersonOutlineOutlinedIcon className={classes.wrapIcon} />
          {t('employee.statusText.working')}
        </Typography>
        <Typography className={classes.wrapIcon} style={{color: Colors.byClient}}>
          <PersonOutlineOutlinedIcon className={classes.wrapIcon} />
          {t('employee.statusText.atClient')}
        </Typography>
        <div className={classes.wrapIcon} style={{color: Colors.text}}>
          <PersonOutlineOutlinedIcon className={classes.wrapIcon} />
          {t('employee.statusText.notWorking')}
        </div>
      </Paper>
      <TablePagination
        component="div"
        className={classes.paginationContainer}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage={''}
        labelDisplayedRows={({from, to, count}) => `${from} tot ${to} van ${count} medewerkers`}
        count={props.total}
        rowsPerPage={pagination.size}
        page={pagination.page}
        SelectProps={{
          inputProps: {'aria-label': 'rows per page'}
        }}
        onPageChange={_onPageChanged}
        onChangeRowsPerPage={_onRowPerPageChanged}
        nextIconButtonProps={{color: 'primary'}}
        backIconButtonProps={{color: 'primary'}}
        classes={{
          select: classes.paginationSelect,
          actions: classes.paginationArrow
        }}
      />
    </TableContainer>
  )
}

export default EmployeeTable

const TEAM_REQUESTED = 'safe/team/fetch_request'
const TEAM_FAILED = 'safe/team/fetch_failed'
const TEAM_SUCCEEDED = 'safe/team/fetch_succeed'
const TEAM_PAGED_REQUESTED = 'safe/team/paged/fetch_requested'
const TEAM_PAGED_SUCCEEDED = 'safe/team/paged/fetch_succeed'
const TEAM_PAGED_FAILED = 'safe/team/paged/fetch_failed'
const TEAM_DETAIL_REQUESTED = 'safe/team/detail/fetch_requested'
const TEAM_DETAIL_SUCCEEDED = 'safe/team/detail/fetch_succeed'
const TEAM_DETAIL_FAILED = 'safe/team/detail/fetch_failed'
const TEAM_DETAIL_CLEARED = 'safe/team/detail/cleared'
const TEAM_DETAIL_SAVE_REQUESTED = 'safe/team/detail/save_requested'
const TEAM_DETAIL_SAVE_SUCCEEDED = 'safe/team/detail/save_succeed'
const TEAM_DETAIL_SAVE_FAILED = 'safe/team/detail/save_failed'
const TEAM_DETAIL_DELETE_REQUESTED = 'safe/team/detail/delete_requested'
const TEAM_DETAIL_DELETE_SUCCEEDED = 'safe/team/detail/delete_succeed'
const TEAM_DETAIL_DELETE_FAILED = 'safe/team/detail/delete_failed'
const ERROR_MSG_CLEAR = 'safe/team/error/clear'
export {
  TEAM_REQUESTED,
  TEAM_SUCCEEDED,
  TEAM_FAILED,
  TEAM_PAGED_REQUESTED,
  TEAM_PAGED_SUCCEEDED,
  TEAM_PAGED_FAILED,
  TEAM_DETAIL_REQUESTED,
  TEAM_DETAIL_SUCCEEDED,
  TEAM_DETAIL_FAILED,
  TEAM_DETAIL_CLEARED,
  TEAM_DETAIL_SAVE_REQUESTED,
  TEAM_DETAIL_SAVE_SUCCEEDED,
  TEAM_DETAIL_SAVE_FAILED,
  TEAM_DETAIL_DELETE_REQUESTED,
  TEAM_DETAIL_DELETE_SUCCEEDED,
  TEAM_DETAIL_DELETE_FAILED,
  ERROR_MSG_CLEAR
}

import React from 'react'
import {UserRole} from '../commons/enums'

export function useEmployeeAuthorization(allowedRoles: Array<UserRole>): Boolean {
  let isAuthorized = false
  const userString = sessionStorage.getItem('loggedInUser')

  if (userString && allowedRoles?.length > 0) {
    const user = JSON.parse(userString)
    isAuthorized =
      user.systemRoles.filter((value: any) => allowedRoles.includes(value.name)).length > 0
  } else if (userString) {
    isAuthorized = true
  }

  return isAuthorized
}

export function useEmployeeAuthenticated(): Boolean {
  const userString = sessionStorage.getItem('loggedInUser')
  return userString !== null && userString.trim() !== ''
}

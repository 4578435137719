import React from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SafeTable from '../SafeTable'
import Colors from '../../commons/constants/colors'

const useStyles = makeStyles({
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem'
  }
})

const GenericSummaryTableAndChart = props => {
  const classes = useStyles()
  let dataArr = []
  if (props.data && props.data.length > 0) {
    dataArr = props.data.reduce((result, item) => {
      if (item[0] !== 'Totaal') {
        result.push({
          key: item[0],
          value: item[1],
          label: item[2]
        })
      }
      return result
    }, [])
  }

  const COLORS = [
    Colors.primaryLight,
    Colors.primary,
    Colors.primaryDark,
    Colors.primaryDarkest
  ]
  const RADIAN = Math.PI / 180

  const _renderCustomizedChartLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        fontSize="0.75rem"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  return (
    <Grid className={classes.grid} container spacing={0}>
      <Grid item md={6}>
        <SafeTable title={props.title} rows={props.data} type="contained" />
      </Grid>
      <Grid item md={6}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
            <Pie
              data={dataArr}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={_renderCustomizedChartLabel}
              isAnimationActive={false}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              nameKey="key"
            >
              {dataArr.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                )
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  )
}

GenericSummaryTableAndChart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string
}

export default GenericSummaryTableAndChart

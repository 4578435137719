import React, {Children} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Colors from '../commons/constants/colors'
import {TableFooter} from '@material-ui/core'
const useStyles = makeStyles({
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: Colors.primary,
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: Colors.secondary,
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  plainTitle: {
    backgroundColor: 'white',
    color: Colors.secondary,
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  }
})

const SafeTable = (props) => {
  const classes = useStyles()
  let headerClass
  let size = props.size || 'medium'
  switch (props.type) {
    case 'contained':
      headerClass = classes.containedTitle
      break
    case 'plain':
      headerClass = classes.plainTitle
      break
    case 'primary':
    default:
      headerClass = classes.primaryTitle
      break
  }

  return (
    <TableContainer className={classes.table}>
      <h4 className={headerClass}>{props.title}</h4>
      <Table className={classes.table} size={size} aria-label="caption table">
        {props.headers ? (
          <TableHead className={classes.tableHead}>
            <TableRow>
              {props.headers.map((title) => (
                <TableCell className={classes.tableHeadCell}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : (
          ''
        )}
        <TableBody>
          {props.rows.length > 0 ? (
            props.rows.map((row) => (
              <TableRow>
                {row.map((cell) => (
                  <TableCell className={classes.row}>{cell}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow></TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2}>{props.footer}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

SafeTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  type: PropTypes.string,
  size: PropTypes.string,
  headers: PropTypes.array,
  footer: PropTypes.oneOf([PropTypes.string, PropTypes.element])
}

export default SafeTable

import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {useTranslation} from 'react-i18next'
import {differenceInCalendarDays, format, subDays} from 'date-fns'
import {nl} from 'date-fns/locale'

import RestRequester from '../../api/RestRequester'
import SafeTable from '../../components/SafeTable'
import AlarmTrendChart from '../../components/reports/AlarmTrendChart'
import GenericSummaryTableAndChart from '../../components/reports/GenericSummaryTableAndChart'
import TeamSummaryChart from '../../components/reports/TeamSummaryChart'
import ReportDateFilter from '../../components/reports/ReportDateFilter'
import ExportButton from './ExportButton'
import sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'
import {useEmployeeAuthorization, useEmployeeAuthenticated} from '../../hooks/EmployeeIsAuthorized'
import {UserRole} from '../../commons/enums'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F4F7'
  },
  btn: {
    marginLeft: '0.5rem',
    padding: '4px 9px',
    fontSize: '0.8125rem'
  },
  btnText: {
    textTransform: 'lowercase',
    color: '#C7C7C7'
  },
  filterContainer: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  pageTitle: {
    display: 'inline',
    paddingRight: sizes.defaultPadding
  },
  select: {
    margin: '0.5rem',
    minWidth: '300px',
    color: '#C7C7C7'
  },
  tableContainer: {
    padding: '1rem'
  },
  title: {
    color: Colors.secondary
  }
})

const ReportScreen = () => {
  const classes = useStyles({})

  const [startDate, setStartDate] = useState(subDays(new Date(), 60))
  const [endDate, setEndDate] = useState(new Date())
  const [teams, setTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState(0)
  const [liveStatus, setLiveStatus] = useState([])
  const [alarmReport, setAlarmReport] = useState([])
  const [teamSummary, setTeamSummary] = useState([])
  const [teamSummaryChart, setTeamSummaryChart] = useState({})
  const [alarmTrend, setAlarmTrend] = useState([])
  const [isLoading, setLoading] = useState(false)
  const canExportReport = useEmployeeAuthorization([UserRole.ADMIN, UserRole.CLIENT_ADMIN])
  const {t} = useTranslation()

  useEffect(() => {
    document.title = 'SAFE | Rapport'
    RestRequester.getLiveStatus(selectedTeam).then((response) => {
      if (response.status === 200) {
        setLiveStatus(_buildLiveStatus(response.data))
      }
    })
    RestRequester.getTeamList().then((response) => {
      if (response.status === 200) {
        setTeams(response.data)
      }
    })
    RestRequester.getAppointmentAndAlarmReport(
      startDate.getTime(),
      endDate.getTime(),
      selectedTeam
    ).then((response) => {
      if (response.status === 200) {
        setAlarmReport(_buildAlarmReport(response.data))
      }
    })
    RestRequester.getTeamReport(startDate.getTime(), endDate.getTime()).then((response) => {
      if (response.status === 200) {
        const data = response.data.map((row) => {
          let appointmentWithAlarmsCount = '0%'
          if (row.numberAppointments) {
            appointmentWithAlarmsCount =
              ((row.numberAppointmentsWithAlarms / row.numberAppointments) * 100).toFixed(2) + '%'
          }
          const resultArray = [row.teamName, row.numberAlarmHelp, row.numberAlarm112]
          return resultArray
        })
        setTeamSummaryChart(response.data)
        setTeamSummary(data)
      }
    })
    RestRequester.getAlarmTrend(selectedTeam).then((response) => {
      setAlarmTrend(response.data)
    })
  }, [startDate, endDate, selectedTeam])

  const _handleSearchClicked = (startDate, endDate, teamId) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setSelectedTeam(teamId)
  }

  const _buildLiveStatus = (data) => {
    let liveStat = []
    let lastAlarm = data.lastAlarm != null ? data.lastAlarm + ' dagen' : 'Geen alarm'
    liveStat.push(['Aantal dagen sinds laatste alarm', lastAlarm])
    liveStat.push([
      'Aantal collega’s aan het werk',
      data.workingEmployee + ' van ' + data.totalEmployee + ' medewerkers'
    ])
    liveStat.push([
      'Aantal collega’s niet aan het werk',
      data.notWorkingEmployee + ' van ' + data.totalEmployee + ' medewerkers'
    ])
    return liveStat
  }

  const _buildAlarmReport = (data) => {
    let alarmReport = []
    const total = data.alarmTotal
    let totalPercentage = 0,
      percentage = 0

    percentage = (data.alarmHelp / total) * 100
    totalPercentage += percentage
    alarmReport.push([
      "Alarmtype 'Help'",
      data.alarmHelp,
      isNaN(percentage) ? '0%' : percentage.toFixed(2) + '%'
    ])

    percentage = (data.alarm112 / total) * 100
    totalPercentage += percentage
    alarmReport.push([
      "Alarmtype 'Bel 112'",
      data.alarm112,
      isNaN(percentage) ? '0%' : percentage.toFixed(2) + '%'
    ])

    // TODO: add false alarm data here
    percentage = (data.alarmCanceled / total) * 100
    totalPercentage += percentage
    alarmReport.push([
      'Vals alarm',
      data.alarmCanceled,
      isNaN(percentage) ? '0%' : percentage.toFixed(2) + '%'
    ])

    alarmReport.push([
      'Totaal',
      total,
      isNaN(totalPercentage) ? '0%' : Math.round(totalPercentage) + '%'
    ])
    return alarmReport
  }

  let teamList = []
  teams.forEach((element) => {
    teamList.push(
      <option value={element.teamId} key={element.teamId}>
        {element.name}
      </option>
    )
  })

  let teamSummaryList = []
  let teamSummaryChartArr = Object.values(teamSummaryChart)
  teamSummaryList = teamSummaryChartArr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 8)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  const exportReport = (docType) => {
    setLoading(true)

    RestRequester.exportReport(docType, startDate.getTime(), endDate.getTime(), selectedTeam).then(
      (response) => {
        setLoading(false)
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const contentDisposition = response.headers['content-disposition']

          link.href = url
          link.setAttribute(
            'download',
            contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim()
          )
          document.body.appendChild(link)
          link.click()
        } else {
          console.error('Error exporting report')
        }
      }
    )
  }

  const exportAppointmentList = () => {
    setLoading(true)
    RestRequester.exportAppointmentList(startDate.getTime(), endDate.getTime(), selectedTeam).then(
      (response) => {
        setLoading(false)
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const contentDisposition = response.headers['content-disposition']

          link.href = url
          link.setAttribute(
            'download',
            contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim()
          )
          document.body.appendChild(link)
          link.click()
        } else {
          console.error('Error exporting appointment list')
        }
      }
    )
  }

  return (
    <>
      <Container>
        <div>
          <h3 className={classes.pageTitle}>Rapport</h3>
          {canExportReport && (
            <>
              <ExportButton onExportClicked={(type) => exportReport(type)} />
              <Button
                variant="outlined"
                color="primary"
                className={classes.btn}
                onClick={exportAppointmentList}
                size="medium">
                {t('report.exportAppointment')}
              </Button>
            </>
          )}
        </div>

        <div>
          <ReportDateFilter date={new Date()} callback={_handleSearchClicked} teamList={teams} />
        </div>
        <Grid className={classes.grid} container spacing={0}>
          <Grid item md={9}>
            <SafeTable title="Live Status" rows={liveStatus} />
          </Grid>
        </Grid>
        <GenericSummaryTableAndChart title="AlarmMeldingen" data={alarmReport} />
        <Grid className={classes.grid} container spacing={0}>
          <Grid item md={12}>
            <Paper className={classes.tableContainer}>
              <AlarmTrendChart data={alarmTrend} />
            </Paper>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={0}>
          <Grid item md={12}>
            <SafeTable
              title="Alarm meldingen per Team"
              rows={teamSummary}
              type="plain"
              size="small"
              headers={['Team naam', "Alarmtype 'Help'", "Alarmtype 'Bel 112'"]}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={0}>
          <Grid item md={12}>
            <Paper className={classes.tableContainer}>
              <h3 className={classes.title}>Trend overzicht per team</h3>
              {teamSummaryList.map((item) => (
                <TeamSummaryChart data={item} />
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <LoadingIndicator open={isLoading} />
    </>
  )
}

export default ReportScreen

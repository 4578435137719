import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {useTranslation} from 'react-i18next'
import {format, parseISO} from 'date-fns'
import {nl} from 'date-fns/locale'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LinearProgress from '@material-ui/core/LinearProgress';

import sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import { DeviceType } from '../../commons/enums'
import MobileUploadButton from './MobileUploadButton'
import { ReleaseNote, MobileApplication } from './types'

export interface ItemProps {
  note: ReleaseNote,
  onDeleteNote: () => void,
  onEditNote: () => void,
  onUploadApp: (file: File, app: MobileApplication) => void,
  onDeleteApp: (type: DeviceType, app: MobileApplication) => void,
  onLoadApps: (version: string) => Promise<any>,
  uploadProgress: number,
  isAdmin: boolean,
  drawDivider: boolean
}

const useItemStyles = makeStyles({
  btn: {
    marginLeft: '0.5rem',
  },
  btnContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: sizes.defaultPadding
  },
  detailContainer: {
    flexDirection: 'column'
  },
  itemTitle: {
    fontWeight: 'bold'
  },
  loadingContainer: {
    marginLeft: sizes.defaultMargin
  },
  progressContainer: {
    padding: 0,
    width: '100%'
  },
  root: {
    color: Colors.text,
    width: '100%',
    flexDirection: 'column'
  },
  titleContainer: {
    alignItems: 'center',
    color: 'black'
  }
})

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: Colors.text,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const ReleaseItem: React.FC<ItemProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isLoadingApp, showLoading] = useState<boolean>(false)
  const [androidApp, setAndroidApp] = useState<MobileApplication>({
    platform: DeviceType.ANDROID,
    release: props.note
  })
  const [iOSApp, setiOSApp] = useState<MobileApplication>({
    platform: DeviceType.IOS,
    release: props.note
  })

  const classes = useItemStyles()
  const {t} = useTranslation()

  useEffect(() => {
    showLoading(true)
    props.onLoadApps(props.note.version)
      .then((response) => {
        showLoading(false)
        const payload = response.data
        if (response.status === 200 && payload.success) {
          payload.data.forEach((app: any) => {
            if(app.platform === DeviceType.ANDROID) {
              setAndroidApp(app)
            } else if (app.platform === DeviceType.IOS) {
              setiOSApp(app)
            }
          })
        }
      })
      .catch((error) => showLoading(false))
  }, [])

  return (
    <>
    <ListItem alignItems="flex-start" className={classes.root}>
      <Grid container spacing={2} className={classes.titleContainer}>
        <Grid item md={4} >
          <div className={classes.itemTitle}>{t('release.version') + ' ' + props.note.version}</div>
          <Typography variant="body2">
            {t('release.releaseDate') + ': ' + format(parseISO(props.note.releaseDate), 'dd MMMM yyyy', {locale: nl})}
          </Typography>
        </Grid>
        {props.isAdmin && (
          <Grid item md={8}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={props.onEditNote}
              size="small">
              {t('common.edit')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={props.onDeleteNote}
              size="small">
              {t('common.delete')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div dangerouslySetInnerHTML={{__html: props.note.releaseNote}} />
        </Grid>
      </Grid>
      {props.isAdmin && (
      <Accordion elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Expand to upload</Typography>
        {isLoadingApp && <div className={classes.loadingContainer}><CircularProgress color='secondary' size='1rem' /></div> }
        </AccordionSummary>
        <AccordionDetails className={classes.detailContainer}>
        {(props.uploadProgress !== 0 && props.uploadProgress !== 100) && (
          <div className={classes.progressContainer}>
            <LinearProgress variant="determinate" value={props.uploadProgress} />
          </div>
        )}  
          <Grid container spacing={2}>
            <Grid item md={6}>
              <MobileUploadButton 
                fileType={DeviceType.IOS} 
                onUpload={(file) => props.onUploadApp(file, iOSApp)}
                onDelete={(app) => props.onDeleteApp(DeviceType.IOS, app)}
                app={iOSApp}/>
            </Grid>
            <Grid item md={6}>
              <MobileUploadButton 
                fileType={DeviceType.ANDROID} 
                onUpload={(file) => props.onUploadApp(file, androidApp)}
                onDelete={(app) => props.onDeleteApp(DeviceType.ANDROID, app)} 
                app={androidApp}/>
            </Grid>
          </Grid>  
        </AccordionDetails>
      </Accordion>
      )}
    </ListItem>
    {props.drawDivider && <Divider variant="middle"/>}
    </>
  )
}

export default ReleaseItem
import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  loadingContainer: {
    padding: '2rem'
  }
}))

const LoadingIndicator = props => {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <Paper className={classes.loadingContainer}>
        <CircularProgress />
      </Paper>
    </Backdrop>
  )
}

LoadingIndicator.propTypes = {
  open: PropTypes.bool.isRequired
}

export default LoadingIndicator

import * as types from './types'

const INITIAL_STATE = {
  isLoading: false,
  errorMsg: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DOWNLOAD_REQUESTED:
      return {...state, isLoading: true}
    case types.DOWNLOAD_SUCCEEDED:
      return {...state, isLoading: false}
    case types.DOWNLOAD_FAILED:
      return {...state, isLoading: false, errorMsg: action.payload.msg}
    default:
      return state
  }
}

export default reducer

import {all, call, put, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as types from './types'
import * as actions from './actions'

function* requestDownload(action) {
  try {
    //   switch (device) {
    //   case 'android':
    //     RestRequester.downloadApp(device).then(response => {
    //       const a = document.createElement('a')
    //       document.body.appendChild(a)
    //       a.style = 'display: none'

    //       const blob = new Blob([response.data], {type: 'application/vnd.android.package-archive'}),
    //         url = window.URL.createObjectURL(blob)
    //       console.log('blob size', blob.size)
    //       a.href = url
    //       a.download = 'safe-app.apk'
    //       a.click()
    //       window.URL.revokeObjectURL(url)
    //     })
    //     break
    //   case 'ios':
    //     window.location.href =
    //       'itms-services://?action=download-manifest&url=https://' +
    //       window.location.host +
    //       '/test/v2/api/download/ios'
    //     break
    //   default:
    //     console.error('device type is not supported')
    //     break
    // }
    switch (action.payload.platform) {
      case 'android':
        // const response = yield call(() => RestRequester.downloadApp(action.payload.platform))

        // const a = document.createElement('a')
        // document.body.appendChild(a)
        // a.style = 'display: none'

        // const blob = new Blob([response.data], {type: 'application/vnd.android.package-archive'}),
        //   url = window.URL.createObjectURL(blob)
        // console.log('blob size', blob.size)
        // a.href = url
        // a.download = 'safe-app.apk'
        // a.click()
        // window.URL.revokeObjectURL(url)
        window.location.href = window.location.host + '/admin/m/android/safe-app.apk'
        break
      case 'ios':
        window.location.href =
          'itms-services://?action=download-manifest&url=https://' +
          window.location.host +
          '/admin/m/ios/manifest.plist'
        break
      default:
        yield put(actions.downloadFailed('device type is not supported'))
        return
    }

    yield put(actions.downloadSucceed())
  } catch (error) {
    console.log(error)
    yield put(actions.downloadFailed('Failed downloading app'))
  }
}

function* saga() {
  yield all([takeLatest(types.DOWNLOAD_REQUESTED, requestDownload)])
}

export default saga

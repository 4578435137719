import {all, call, put, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as types from './types'
import * as actions from './actions'
import {hasRole} from '../../commons/utils'
import {UserRole} from '../../commons/enums'

function* requestLogin(action) {
  const history = action.payload.history
  sessionStorage.setItem('clientId', action.payload.clientId)
  try {
    const response = yield call(() =>
      RestRequester.login(action.payload.username, action.payload.password)
    )
    if (response.status === 200) {
      const token = response.headers['authorization']
      if (token) {
        yield put(actions.loginSucceed(token, action.payload.username, history))
      }
    }
  } catch (error) {
    console.log(error)
    sessionStorage.removeItem('clientId')
    yield put(actions.loginFailed('auth.authFailed'))
  }
}

function* loginSucceeded(action) {
  const history = action.payload.history
  const token = action.payload.token
  if (token) {
    // TODO: find more secure way to save the jwt
    sessionStorage.setItem('token', token)
    yield put(actions.requestLoggedInUser(history))
  }
}

function* requestLogout(action) {
  const history = action.payload.history

  sessionStorage.removeItem('token')
  sessionStorage.removeItem('loggedInUser')
  yield put(actions.logoutSucceeded(history))
}

function* logoutSucceeded(action) {
  const history = action.payload.history

  yield call(() => history.push('/'))
}

function* requestChangePassword(action) {
  const history = action.payload.history

  try {
    const response = yield call(() =>
      RestRequester.changePassword(action.payload.oldPass, action.payload.newPass)
    )
    if (response.status === 200) {
      yield put(actions.changePasswordSucceeded(history))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.changePasswordFailed('Fail changing password, please try again.'))
  }
}

function* changePasswordSuccess(action) {
  yield call(() => action.payload.history.push('/'))
}

function* requestValidateToken(action) {
  const history = action.payload.history
  try {
    const response = yield call(() =>
      RestRequester.validateResetToken(action.payload.token, action.payload.clientId)
    )
    if (response.status === 200) {
      yield put(actions.validateResetTokenSucceeded(history))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.validateResetTokenFailed('Fail validating token, please try again.'))
  }
}

function* requestResetToken(action) {
  const history = action.payload.history
  try {
    const response = yield call(() =>
      RestRequester.requestResetToken(action.payload.clientId, action.payload.email)
    )
    if (response.status === 200) {
      yield put(actions.resetTokenSucceeded(action.payload.email, history))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.resetTokenFailed('Fail requesting reset token, please try again.'))
  }
}

function* requestResetPasswordByToken(action) {
  const history = action.payload.history
  try {
    const response = yield call(() =>
      RestRequester.resetPasswordByToken(
        action.payload.token,
        action.payload.clientId,
        action.payload.password
      )
    )
    if (response.status === 200) {
      yield put(actions.resetPasswordByTokenSucceeded(action.payload.token, history))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.resetPasswordByTokenFailed('Fail requesting reset token, please try again.'))
  }
}

function* requestLoggedInUser(action) {
  const history = action.payload.history
  try {
    const response = yield call(() => RestRequester.getMyDetail())
    if (response.status === 200) {
      const user = response.data
      if (
        hasRole(user.systemRoles, UserRole.ADMIN) ||
        hasRole(user.systemRoles, UserRole.CLIENT_ADMIN) ||
        hasRole(user.systemRoles, UserRole.TEAM_MANAGER)
      ) {
        yield put(actions.requestLoggedInUserSuccess(user, history))
      } else {
        yield put(actions.requestLoggedInUserFailed('auth.noAccess'))
      }
    }
  } catch (error) {
    console.log(error.response)
    yield put(actions.requestLoggedInUserFailed(error.response.data))
  }
}

function* requestLoggedInUserSuccess(action) {
  sessionStorage.setItem('loggedInUser', JSON.stringify(action.payload.user))
  yield call(() => action.payload.history.push('/medewerker'))
}

function* requestLoggedInUserFailed(action) {
  sessionStorage.removeItem('loggedInUser')
  sessionStorage.removeItem('token')
  yield call(() => action.payload.history.push('/medewerker'))
}

function* saga() {
  yield all([
    takeLatest(types.AUTH_REQUESTED, requestLogin),
    takeLatest(types.AUTH_SUCCEEDED, loginSucceeded),
    takeLatest(types.LOGOUT_REQUESTED, requestLogout),
    takeLatest(types.LOGOUT_SUCCEEDED, logoutSucceeded),
    takeLatest(types.CHANGE_PASSWORD_REQUESTED, requestChangePassword),
    takeLatest(types.CHANGE_PASSWORD_SUCCEEDED, changePasswordSuccess),
    takeLatest(types.VALIDATE_RESET_TOKEN_REQUESTED, requestValidateToken),
    takeLatest(types.RESET_TOKEN_REQUESTED, requestResetToken),
    takeLatest(types.RESET_PASSWORD_BY_TOKEN_REQUESTED, requestResetPasswordByToken),
    takeLatest(types.GET_LOGGED_IN_USER_REQUESTED, requestLoggedInUser),
    takeLatest(types.GET_LOGGED_IN_USER_SUCCEEDED, requestLoggedInUserSuccess),
    takeLatest(types.GET_LOGGED_IN_USER_FAILED, requestLoggedInUserFailed)
  ])
}

export default saga

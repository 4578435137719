import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/Accordion'
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import Constants from '../../commons/constants/constants'

const useStyles = makeStyles({
  btn: {
    marginRight: Sizes.defaultMargin
  },
  titleContainer: {
    backgroundColor: Colors.primary,
    color: 'white',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    justifyContent: 'flex-start'
  },
  contentSummaryContainer: {
    flexGrow: 0
  },
  detailContainer: {
    backgroundColor: Colors.background,
    paddingLeft: 0,
    paddingRight: 0
  },
  heading: {
    fontSize: '1.5rem'
  },
  inputField: {
    backgroundColor: 'white',
    color: Colors.textLight,
    borderColor: 'white'
  }
})

const AddressFilter = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const defaultFilter = Constants.defaultAddressFilter
  const [filter, setFilter] = useState(defaultFilter)

  const _handleChange = (event) => {
    const updatedFilter = {
      ...filter,
      [event.target.id]: event.target.value
    }
    setFilter(updatedFilter)
  }

  const _handleReset = () => {
    setFilter(defaultFilter)
    props.onSearch(defaultFilter)
  }

  const _onSearchClicked = () => {
    let resultFilter = Object.assign(filter)
    props.onSearch(resultFilter)
  }

  return (
    <ExpansionPanel elevation={0}>
      <ExpansionPanelSummary
        classes={{
          root: classes.titleContainer,
          content: classes.contentSummaryContainer
        }}
        expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography className={classes.heading}>{t('common.search')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.detailContainer}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TextField
              id="address"
              label={t('location.address')}
              variant="outlined"
              fullWidth
              value={filter.address}
              className={classes.inputField}
              size="small"
              onChange={_handleChange}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              id="additionalInfo"
              label={t('location.additionalInfo')}
              variant="outlined"
              value={filter.additionalInfo}
              className={classes.inputField}
              fullWidth
              size="small"
              onChange={_handleChange}
            />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={_onSearchClicked}>
              {t('common.search')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={_handleReset}>
              {t('common.reset')}
            </Button>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

AddressFilter.propTypes = {
  onSearch: PropTypes.func.isRequired
}
export default AddressFilter

import {all, call, put, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as types from './types'
import * as actions from './actions'

function* requestAppointmentsWithAlarm(action) {
  try {
    const response = yield call(() => RestRequester.getAppointmentsWithAlarm())
    // const history = action.payload.history
    yield put(actions.appointmentsWithAlarmSucceeded(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.appointmentsWithAlarmFailed())
  }
}

function* saga() {
  yield all([takeLatest(types.APPOINTMENT_WITH_ALARM_REQUESTED, requestAppointmentsWithAlarm)])
}

export default saga
